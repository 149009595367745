import React, { useEffect, useState } from 'react'
import {
    Button,
    Input,
    Select,
    Row,
    Tag,
    Table,
    Modal,
} from 'antd';
import axios from 'axios';
import API_ROUTES from '../../../api';
import { useNavigate } from 'react-router-dom'
import { ReloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { RightOutlined, LeftOutlined, DeleteOutlined } from '@ant-design/icons';


export default function HomeSearchResult() {

    const { Search } = Input;

    const [pageTotalCount, setPageTotalCount] = useState(0)
    const [pageData, setPageData] = useState([])
    const [tableLoading, setTableLoadinng] = useState(false);
    const navigate = useNavigate()

    //翻页
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(15)

    //查询结果详情
    const [searchResultInfo, setSearchResultInfo] = useState([])

    //这两个表的showstatus
    const [showStatus, setShowStatus] = useState(true)


    useEffect(() => {
        getSendInfoList()
    }, []);


    const getSendInfoList = (taskName, status, page) => {
        setTableLoadinng(true)
        axios.get(API_ROUTES.api_imputation_list, {
            params: {
                current: page ? page : currentPage,
                size: currentPageSize,
                taskName: taskName,
                status: status
            },
            headers: {
                "Authorization": localStorage.getItem('userInfo')
            }
        })
            .then(function (response) {
                if (response.data.code === 500) {
                    Modal.error({
                        content: response.data.msg
                    });
                } else {
                    setPageTotalCount(response.data.data.total);
                    setPageData(response.data.data.records);
                }
            })
            .catch(function (error) {
                navigate('/login')
                Modal.error({
                    content: error.response.data.msgDes
                });
            })
            .finally(function () {
                setTableLoadinng(false)
            })
    }

    const changePageNumber = (pageNum) => {
        setCurrentPage(pageNum);
        getSendInfoList(tableTaskNameSelect, tableStatusSelect, pageNum)
    };

    const pageSizeChange = (_, currentPage) => {
        setCurrentPageSize(currentPage)
    }

    const handleReloadTable = () => {
        setTableStatusSelect(null)
        setTableTaskNameSelect(null)
        getSendInfoList()
    }

    const getMissionResult = (id) => {
        setTableLoadinng(true)
        axios.get(API_ROUTES.api_imputation_get, {
            params: {
                id: id
            },
            headers: {
                "Authorization": localStorage.getItem('userInfo')
            }
        })
            .then(function (response) {
                if (response.data.code === 500) {
                    Modal.error({
                        content: response.data.msg
                    });
                } else if (response.data.code === 200) {
                    setSearchResultInfo(response.data.data)
                }
            })
            .catch(function (error) {
                navigate('/login')
                Modal.error({
                    content: error.response.data.msgDes
                });
            })
            .finally(function () {
                setTableLoadinng(false)
            })
    }

    //处理查看结果：
    const handleResultInfo = (record) => {
        setShowStatus(false)
        getMissionResult(record.id)
    }

    const columns = [
        {
            title: (
                <div style={{ fontSize: '18px' }}>
                    Send Time
                </div>
            ),
            dataIndex: 'fetchTime',
            key: 'fetchTime',
            render: (text) => moment(text).format('YYYY-MM-DD HH:mm:ss'),
            width: 80,
            ellipsis: true,
        },
        {
            title: (
                <div style={{ fontSize: '18px' }}>
                    Project Name
                </div>
            ),
            dataIndex: 'taskName',
            key: 'taskName',
            width: 100,
            ellipsis: true,
        },
        {
            title: (
                <div style={{ fontSize: '18px' }}>
                    Status
                </div>
            ),
            dataIndex: 'status',
            key: 'status',
            width: 60,
            ellipsis: true,
            render: (_, record) => (
                <Tag color={record.status}>{record.statusInfo}</Tag>
            )
        },
        {
            title: (
                <div style={{ fontSize: '18px' }}>
                    Reference Panel
                </div>
            ),
            dataIndex: 'referencePanel',
            key: 'referencePanel',
            width: 80,
            render: (text) => {
                let displayText;
                switch (text) {
                    case 2:
                        displayText = 'GRCH38/hg38';
                        break;
                    case 1:
                        displayText = 'GRCH37/hg19';
                        break;
                    default:
                        displayText = '';
                }
                return <span>{displayText}</span>;
            },
            ellipsis: true,
        },
        {
            title: (
                <div style={{ fontSize: '18px' }}>
                    Chr
                </div>
            ),
            dataIndex: 'chr',
            key: 'chr',
            width: 40,
            render: (text) => {
                return <span>{"Chr" + text}</span>;
            },
            ellipsis: true,
        },
        {
            title: (
                <div style={{ fontSize: '18px' }}>
                    Upload file
                </div>
            ),
            dataIndex: 'uploadFile',
            key: 'uploadFile',
            width: 180,
            render: (text, record) => {
                return (
                    <a href={text} target="_blank" rel="noopener noreferrer" >
                        {record.uploadFileName}
                    </a>
                );
            },
            ellipsis: true,
        },
        {
            title: (
                <div style={{ fontSize: '18px' }}>
                    Error Info
                </div>
            ),
            dataIndex: 'errorInfo',
            key: 'errorInfo',
            width: 80,
            ellipsis: true,
        },
        {
            title: (
                <div style={{ fontSize: '18px' }}>
                    Others
                </div>
            ),
            dataIndex: 'others',
            key: 'others',
            fixed: 'right',
            width: 60,
            render: (_, record) => (
                <div>
                    <Button
                        style={{ display: record.status === 'success' ? '' : 'none', marginRight: '5px', width: '80px' }}
                        type="primary"
                        size='small'
                        onClick={() => handleResultInfo(record)}>
                        result<RightOutlined />
                    </Button>
                    <Button
                        danger
                        style={{ display: record.status != 'success' ? '' : 'none', width: '80px' }}
                        type="primary"
                        size='small'
                        onClick={() => {
                            Modal.confirm({
                                title: 'Confirm deletion of current task?',
                                content: 'Delete' + record.taskName,
                                closable: true,
                                onOk: () => {
                                    setTableLoadinng(true)
                                    axios.post(API_ROUTES.api_imputation_delete, null, {
                                        headers: {
                                            Authorization: localStorage.getItem('userInfo')
                                        },
                                        params: {
                                            id: record.id
                                        }
                                    })
                                        .then(function (response) {
                                            if (response.data.code === 500) {
                                                Modal.error({
                                                    content: response.data.msg
                                                });
                                            } else {
                                                Modal.success({
                                                    content: 'delete success!'
                                                });
                                            }
                                            getSendInfoList()
                                        })
                                        .catch(function (error) {
                                            Modal.error({
                                                content: error.response.statusText
                                            });
                                        })
                                        .finally(function () {
                                            setTableLoadinng(false)
                                        })
                                }
                            })
                        }}
                    >
                        delete<DeleteOutlined />
                    </Button>
                </div>
            ),
            ellipsis: true,
        },
    ];

    function extractFileName(url) {
        // 使用 URL 对象解析传入的链接
        const parsedUrl = new URL(url);
        // 获取路径名的最后一个部分作为文件名
        const fileName = parsedUrl.pathname.split('/').pop();
        return fileName;
    }


    const resultColumns = [
        {
            title: (
                <div style={{ fontSize: '18px' }}>
                    Project Name
                </div>
            ),
            dataIndex: 'taskName',
            key: 'taskName',
            width: 60,
            ellipsis: true,
        },
        {
            title: (
                <div style={{ fontSize: '18px' }}>
                    Result File
                </div>
            ),
            dataIndex: 'uploadFile',
            key: 'uploadFile',
            width: 180,
            render: (_, record) => {
                return (
                    <div>
                        {record.status === 1 ?
                            <a href={record.fileUrl} target="_blank" rel="noopener noreferrer" >
                                {extractFileName(record.fileUrl)}
                            </a> : record.status === 2 ?
                                <span style={{ color: 'red' }}>Erorr</span>
                                : <span>
                                    Waiting for upload result file
                                </span>
                        }
                    </div>
                );
            },
            ellipsis: true,
        },
        {
            title: (
                <div style={{ fontSize: '18px' }}>
                    Error Info
                </div>
            ),
            dataIndex: 'errorInfo',
            key: 'errorInfo',
            width: 180,
            ellipsis: true,
        }
    ];

    //模拟一下数据
    const tableData = pageData.map((data) => {
        let status = '';
        let statusInfo = '';

        switch (data.status) {
            case 0:
                status = 'default';
                statusInfo = 'waiting';
                break;
            case 1:
                status = 'processing';
                statusInfo = 'sending';
                break;
            case 2:
                status = 'processing';
                statusInfo = 'processing';
                break;
            case 3:
                status = 'success';
                statusInfo = 'success';
                break;
            case 4:
                status = 'error';
                statusInfo = 'error';
                break;
            default:
                status = 'unknown';
                statusInfo = 'unknown';
        }

        return {
            ...data,
            status,
            statusInfo,
        };
    });


    const [tableStatusSelect, setTableStatusSelect] = useState()
    const [tableTaskNameSelect, setTableTaskNameSelect] = useState("")

    return (
        <div style={{ padding: '50px 180px 0px 180px' }}>
            <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                    type="primary"
                    onClick={() => {
                        setShowStatus(true)
                        setSearchResultInfo([])
                    }
                    }
                    style={{ margin: '10px 0px', width: '100px', display: showStatus ? 'none' : '' }}>
                    <LeftOutlined /> Back
                </Button>

                <Button type="primary" onClick={handleReloadTable}
                    style={{ margin: '10px 0px', width: '100px', display: !showStatus ? 'none' : '' }}
                >
                    <ReloadOutlined /> Reload
                </Button>
                <Select
                    placeholder="Select a status"
                    allowClear
                    style={{
                        width: 160,
                        marginLeft: '10px'
                    }}
                    onChange={(value) => {
                        setTableStatusSelect(value)
                        getSendInfoList(tableTaskNameSelect, value)
                    }}
                    value={tableStatusSelect}
                    options={[
                        {
                            value: 0,
                            label: "waiting",
                        },
                        // {
                        //     value: 1,
                        //     label: 'sending',
                        // },
                        {
                            value: 2,
                            label: 'processing',
                        },
                        {
                            value: 3,
                            label: 'success',
                        },
                        {
                            value: 4,
                            label: 'error',
                        },
                    ]}
                />
                <Search
                    placeholder="Filter task name."
                    onSearch={(value) => {
                        setTableTaskNameSelect(value)
                        getSendInfoList(value, tableStatusSelect)
                    }}
                    style={{
                        width: 200,
                        marginLeft: '10px'
                    }}
                />
            </Row>
            <Table
                style={{ display: !showStatus ? 'none' : '' }}
                columns={columns}
                loading={tableLoading}
                dataSource={tableData}
                scroll={{
                    x: '1500px',
                    y: '500px'
                }}
                size='small'
                tableLayout='fixed'
                pagination={{
                    showQuickJumper: true,
                    defaultPageSize: 10,
                    defaultCurrent: 1,
                    current: currentPage,
                    total: pageTotalCount,
                    onChange: changePageNumber,
                    pageSize: currentPageSize,
                    onShowSizeChange: pageSizeChange
                }}
                bordered="true"
            />
            <Table
                style={{ display: showStatus ? 'none' : '' }}
                columns={resultColumns}
                loading={tableLoading}
                dataSource={searchResultInfo}
                scroll={{
                    x: '1500px',
                    y: '500px'
                }}
                size='small'
                tableLayout='fixed'
                bordered="true"
            />

        </div>
    )
}
