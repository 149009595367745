import {
    Button, Row, Col, Select, Slider,
    Tag, Tooltip, Table, Form, Card, Divider, Modal, Skeleton,
    Empty, Descriptions, Checkbox
} from 'antd'
import React, { Children, useState } from 'react'
import '../index.css'
import { SearchOutlined, FileSearchOutlined, BorderlessTableOutlined } from '@ant-design/icons';
import API_ROUTES from '../../../api';
import axios from 'axios';
import EchartsComponent from '../SearchTwo/EchartsComponent';


export default function SearchOne({ resultInfo }) {

    const [yangyeDetail, setYangyeDetail] = useState()
    const [searchLoading, setSearchLoading] = useState(false)
    const [selectParams, setSelectParams] = useState()


    const checkBoxStyle = {
        fontSize: '16px', fontWeight: '600'
    }

    const nations = [
        { label: 'Bai', value: "N1" },
        { label: 'Bao\'an', value: "N2" },
        { label: 'Blang', value: "N3" },
        { label: 'Bouyei', value: "N4" },
        { label: 'Chuanqing', value: "N7" },
        { label: 'Daur', value: "N8" },
        { label: 'Dai', value: "N9" },
        { label: 'Dongxiang', value: "N10" },
        { label: 'Dong', value: "N11" },
        { label: 'Gaoshan', value: "N13" },
        { label: 'Gelao', value: "N14" },
        { label: 'Han', value: "N17" },
        { label: 'Hani', value: "N15" },
        { label: 'Hui', value: "N18" },
        { label: 'Jingpo', value: "N19" },
        { label: 'Kazakh', value: "N16" },
        { label: 'Korean', value: "N6" },
        { label: 'Kyrgyz', value: "N20" },
        { label: 'Lahu', value: "N21" },
        { label: 'Li', value: "N22" },
        { label: 'Lisu', value: "N23" },
        { label: 'Manchu', value: "N24" },
        { label: 'Maonan', value: "N25" },
        { label: 'Menba', value: "N26" },
        { label: 'Mongolian', value: "N27" },
        { label: 'Miao', value: "N28" },
        { label: 'Mosuo', value: "N29" },
        { label: 'Mulao', value: "N30" },
        { label: 'Naxi', value: "N31" },
        { label: 'Pumi', value: "N32" },
        { label: 'Qiang', value: "N33" },
        { label: 'Russian', value: "N12" },
        { label: 'Salar', value: "N34" },
        { label: 'She', value: "N35" },
        { label: 'Shui', value: "N36" },
        { label: 'Tajik', value: "N37" },
        { label: 'Tibetan', value: "N5" },
        { label: 'Tujia', value: "N38" },
        { label: 'Tu', value: "N39" },
        { label: 'Uyghur', value: "N41" },
        { label: 'Va', value: "N40" },
        { label: 'Xibe', value: "N42" },
        { label: 'Yao', value: "N43" },
        { label: 'Yi', value: "N44" },
        { label: 'Yugu', value: "N45" },
        { label: 'Zhuang', value: "N46" },
        { label: 'NA', value: "NA" }
    ];

    //民族选项
    const nation = nations.map((ethnicity, index) =>
        <Col span={6}>
            <Checkbox key={index} value={ethnicity.value} style={checkBoxStyle}>
                {ethnicity.label}
            </Checkbox>
        </Col>);

    //地域选项
    const regions = [
        { label: 'Northeast China', value: "R1" },
        { label: 'East China', value: "R2" },
        { label: 'North China', value: "R3" },
        { label: 'Central China', value: "R4" },
        { label: 'South China', value: "R5" },
        { label: 'Northwest China', value: "R6" },
        { label: 'Southwest China', value: "R7" }
    ];

    const region = regions.map((region, index) =>
        <Col span={6}>
            <Checkbox key={index + 1} value={"R" + (index + 1)}
                style={checkBoxStyle}
            >
                {region.label}
            </Checkbox>
        </Col>
    );

    const diseases = [
        { label: 'Natural Population', value: "D0" },
        { label: 'Central Nervous System', value: "D1" },
        { label: 'Cardiovascular System', value: "D2" },
        { label: 'Respiratory System', value: "D3" },
        { label: 'Digestive System', value: "D4" },
        { label: 'ENT System (Ear, Nose, and Throat)', value: "D5" },
        { label: 'Ophthalmic System', value: "D6" },
        { label: 'Oral and Maxillofacial System', value: "D7" },
        { label: 'Hematological System', value: "D8" },
        { label: 'Urogenital System', value: "D9" },
        { label: 'Musculoskeletal System', value: "D10" },
        { label: 'Endocrine and Metabolic System', value: "D11" },
        { label: 'Immune System', value: "D12" },
        { label: 'Integumentary System', value: "D13" }
    ];


    const disease = diseases.map((disease, index) =>
        <Col span={12}>
            <Checkbox key={index + 1} value={disease.value} style={checkBoxStyle}>
                {disease.label}
            </Checkbox>
        </Col>
    );

    const [ageRange, setAgeRange] = useState([0, 100]);
    const [dieseaseSearch, setDieaseSearch] = useState(['D0'])
    const [selectedRegions, setSelectedRegions] = useState(
        regions.map(region => region.value)
    );
    const [selectGender, setSelectGender] = useState(['G1', 'G2', 'NA'])
    const [selectNation, setSelectNation] = useState(
        nations.map(nation => nation.value)
    )

    return (
        <div>
            {resultInfo === "chr13:20189473-C-T" &&
                <Card style={{
                    display: 'flex',
                    alignItems: 'center',
                    border: '0px',
                    justifyContent: 'center'
                }}>
                    <img src={"https://gsrd-web-images.tos-cn-beijing.volces.com/search7.png"} />
                </Card>
            }
            <Divider />
            <span className='title_name' style={{ display: 'flex', alignItems: 'center', fontWeight: '700' }}>
                <img src='https://gsrd-web-images.tos-cn-beijing.volces.com/gene9.png'
                    style={{ height: '30px', width: '30px', marginRight: '10px' }}
                />
                Search
            </span>
            <Card style={{ border: '0px' }}>
                <Row >
                    <Col span={12} style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                        <Row gutter={16} style={{ marginBottom: '20px' }}>
                            <Col>
                                <span style={{ fontSize: '24px', fontWeight: '700' }}>Region:</span>
                            </Col>
                            <Col style={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox onChange={(e) => {
                                    if (e.target.checked) {
                                        setSelectedRegions(regions.map(region => region.value));
                                    } else {
                                        setSelectedRegions([]);
                                    }
                                }}
                                    defaultChecked='true'
                                    indeterminate={selectedRegions.length > 0 && selectedRegions.length < regions.length}
                                    style={checkBoxStyle}
                                >Select all</Checkbox>
                            </Col>
                        </Row>
                        <Row>
                            <Checkbox.Group
                                style={{
                                    width: '100%',
                                }}
                                value={selectedRegions}
                                onChange={(value) => setSelectedRegions(value)}
                            >
                                <Row >
                                    {region}
                                </Row>
                            </Checkbox.Group>
                        </Row>
                        <Row >
                            <EchartsComponent
                                selectedRegions={selectedRegions}
                                setSelectedRegions={setSelectedRegions}
                            />
                        </Row>

                    </Col>

                    <Col span={12}>
                        <Row>
                            <Col span={4} style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ fontSize: '24px', fontWeight: '700' }}>Age:</span>
                            </Col>
                            <Col span={16}>
                                <Slider
                                    range
                                    marks={{
                                        0: <div style={checkBoxStyle}>0</div>,
                                        6: <div style={checkBoxStyle}>6</div>,
                                        18: <div style={checkBoxStyle}>18</div>,
                                        50: <div style={checkBoxStyle}>50</div>,
                                        100: <div style={checkBoxStyle}>100+</div>,
                                    }}
                                    step={null}
                                    defaultValue={[0, 100]}
                                    value={ageRange}
                                    onChange={(newValue) => {
                                        setAgeRange(newValue);
                                    }}
                                />
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col span={4} style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ fontSize: '24px', fontWeight: '700' }}>Gender:</span>
                            </Col>
                            <Col span={16} style={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox.Group
                                    style={{
                                        width: '100%',
                                    }}
                                    value={selectGender}
                                    onChange={(value) => setSelectGender(value)}
                                >
                                    <Row >
                                        <Col span={8}>
                                            <Checkbox value="G1" style={checkBoxStyle}>Male</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value="G2" style={checkBoxStyle}>Female</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value="NA" style={checkBoxStyle}>NA</Checkbox>
                                        </Col>
                                    </Row>
                                </Checkbox.Group>
                            </Col>
                            <Col span={3} style={{ display: 'flex', alignItems: 'center' }} offset={1}>
                                <Checkbox
                                    indeterminate={selectGender.length > 0 && selectGender.length < 3}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setSelectGender(['G1', 'G2', 'NA']);
                                        } else {
                                            setSelectGender([]);
                                        }
                                    }}
                                    defaultChecked='true'
                                    style={checkBoxStyle}
                                >Select all</Checkbox>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col span={24} style={{ display: 'flex', alignItems: 'center',marginBottom:"10px" }}>
                                <span style={{ fontSize: '24px', fontWeight: '700' }}>Nation:</span>
                            </Col>
                            <Col span={21} style={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox.Group
                                    style={{
                                        width: '100%',
                                    }}
                                    value={selectNation}
                                    onChange={(value) => { setSelectNation(value) }}
                                >
                                    <Row >
                                        {nation}
                                    </Row>
                                </Checkbox.Group>
                            </Col>
                            <Col span={3} style={{ display: 'flex', alignItems: 'center' }} >
                                <Checkbox onChange={(value) => {
                                    if (value.target.checked) {
                                        setSelectNation(nations.map(item => item.value));
                                    } else {
                                        setSelectNation([]);
                                    }
                                }}
                                    defaultChecked='true'
                                    indeterminate={selectNation.length > 0 && selectNation.length < nation.length}
                                    style={checkBoxStyle}
                                >Select all</Checkbox>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col span={24} style={{ display: 'flex', alignItems: 'center',marginBottom:"10px" }}>
                                <span style={{ fontSize: '24px', fontWeight: '700' }}>Phenotype-associated subpopulations:</span>
                            </Col>
                            <Col span={21} style={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox.Group
                                    style={{
                                        width: '100%',
                                    }}
                                    value={dieseaseSearch}
                                    onChange={(value) => setDieaseSearch(value)}
                                >
                                    <Row >
                                        {disease}
                                    </Row>
                                </Checkbox.Group>
                            </Col>
                            <Col span={3} style={{ display: 'flex', alignItems: 'center' }} >
                                <Checkbox onChange={(value) => {
                                    if (value.target.checked) {
                                        setDieaseSearch(diseases.map(item => item.value));
                                    } else {
                                        setDieaseSearch([]);
                                    }
                                }}
                                    defaultChecked='true'
                                    indeterminate={dieseaseSearch.length > 0 && dieseaseSearch.length < disease.length}
                                    style={checkBoxStyle}
                                >
                                    Select all
                                </Checkbox>
                            </Col>
                        </Row>
                        <Row style={{ display: "flex", justifyContent: 'center', marginTop: '20px' }}>
                            <Button type='primary'
                                style=
                                {{
                                    width: '200px',
                                    height: '40px',
                                    fontSize: '18px',
                                    fontWeight: '700'
                                }}
                                shape='round'
                                icon={<SearchOutlined />}
                                iconPosition='end'
                                loading={searchLoading}
                                onClick={() => {
                                    setSearchLoading(true)
                                    setYangyeDetail({})
                                    if (!ageRange) {
                                        setAgeRange([0, 6]);
                                    }
                                    let ageValues = [];
                                    if (ageRange[0] < 6 && ageRange[1] >= 6) {
                                        ageValues.push('A1');
                                    }
                                    if (ageRange[0] < 18 && ageRange[1] >= 18) {
                                        ageValues.push('A2');
                                    }
                                    if (ageRange[0] < 50 && ageRange[1] >= 50) {
                                        ageValues.push('A3');
                                    }
                                    if (ageRange[0] < 100 && ageRange[1] >= 100) {
                                        ageValues.push('A4');
                                    }
                                    ageValues = ageValues.join(",")

                                    axios.post(API_ROUTES.api_search_parseResultInfo, {
                                        id: resultInfo.searchInfo.id,
                                        gender: selectGender.join(","),
                                        nation: selectNation.join(","),
                                        age: ageValues,
                                        disease: dieseaseSearch.join(","),
                                        region: selectedRegions.join(","),
                                    }, {
                                        headers: {
                                            Authorization: localStorage.getItem('userInfo')
                                        }
                                    })
                                        .then(function (response) {
                                            if (response.data.code === 500) {
                                                Modal.error({
                                                    content: response.data.msg
                                                });
                                            } else {
                                                setYangyeDetail(response.data.data)
                                                setSelectParams(
                                                    {
                                                        gender: selectGender,
                                                        nation: selectNation,
                                                        age: ageRange,
                                                        disease: dieseaseSearch,
                                                        region: selectedRegions
                                                    }
                                                )
                                            }
                                        })
                                        .catch(function (error) {
                                            Modal.error({
                                                content: "error"
                                            });
                                        })
                                        .finally(() => {
                                            setSearchLoading(false)
                                        });
                                }}
                            >
                                Submit
                            </Button>
                        </Row>
                    </Col>
                </Row>
            </Card>

            {!selectParams ? null : !searchLoading ?
                <div>
                    <Card style={{ backgroundColor: 'aliceblue', padding: '2px 10px' }} >
                        <Descriptions
                            // title={<span style={{ fontSize: '24px', fontWeight: '700' }}>
                            //     GSRD-100K<sup style={{ fontSize: '14px' }}>WCH</sup>  Frequencies
                            // </span>}
                            size='small'
                            column={3}
                            style={{ margin: '10px 0px' }}
                            items={[
                                {
                                    label:
                                        <span className='colValue'>
                                            Gender
                                        </span>,
                                    children:
                                        <div style={{ width: '100%' }}>
                                            {selectParams.gender.map((item) => (
                                                <Tag color="green" style={{ fontSize: '14px' }}>
                                                    {
                                                        item === 'G1' ? 'Male' :
                                                            item === 'G2' ?
                                                                'Female' : 'NA'
                                                    }
                                                </Tag>
                                            ))}
                                        </div>,
                                },
                                {
                                    label:
                                        <span className='colValue'>
                                            Age
                                        </span>,
                                    children:
                                        <div style={{ width: '100%' }}>
                                            <Tag color="green" style={{ fontSize: '14px' }}>
                                                {ageRange[0] + '-' + ageRange[1]}
                                            </Tag>
                                        </div>,
                                },
                                {
                                    label:
                                        <span className='colValue'>
                                            Region
                                        </span>,
                                    children:
                                        <div style={{ width: '100%' }}>
                                            {selectParams.region
                                                .map((item, index) => {
                                                    const resInfo = regions.find(res => res.value === item);
                                                    return resInfo ? (
                                                        <Tag color="green" style={{ fontSize: '14px', margin: '2px' }} key={index}>
                                                            {resInfo.label}
                                                        </Tag>
                                                    ) : null;
                                                })
                                            }
                                        </div>,
                                },
                                {
                                    label:
                                        <span className='colValue'>
                                            Nation
                                        </span>,
                                    children:
                                        <div style={{ width: '100%' }}>
                                            {selectParams.nation
                                                .map((item, index) => {
                                                    const nation = nations.find(nation => nation.value === item);
                                                    return nation ? (
                                                        <Tag color="green" style={{ fontSize: '14px', margin: '2px' }} key={index}>
                                                            {nation.label}
                                                        </Tag>
                                                    ) : null;
                                                })
                                            }
                                        </div>,
                                },
                                {
                                    label:
                                        <span className='colValue'>
                                            Phenotype-associated subpopulations
                                        </span>,
                                    children:
                                        <div style={{ width: '100%' }}>
                                            {selectParams.disease
                                                .map((item, index) => {
                                                    const resInfo = diseases.find(res => res.value === item);
                                                    return resInfo ? (
                                                        <Tag color="green" style={{ fontSize: '14px', margin: '2px' }} key={index}>
                                                            {resInfo.label}
                                                        </Tag>
                                                    ) : null;
                                                })
                                            }
                                        </div>,
                                },
                            ]}
                        />
                        <Descriptions
                            bordered
                            column={3}
                            items={[
                                {
                                    label:
                                        <span className='colValue'>
                                            Filters
                                        </span>,
                                    children:
                                        <div style={{ width: '100%' }}>
                                            <Tag color="green" style={{ fontSize: '14px' }}>Pass</Tag>
                                        </div>,
                                },
                                {
                                    label:
                                        <Tooltip placement="top" title='Alternate allele count in high quality genotypes'>
                                            <span className='colValue'>
                                                Allele Count
                                            </span>
                                        </Tooltip>,
                                    children:
                                        <div style={{ width: '100%', fontSize: '18px', fontWeight: '600' }}>
                                            {yangyeDetail?.ac ?? 0}
                                        </div>,
                                },
                                {
                                    label:
                                        <Tooltip placement="top" title='Total number of called high quality genotypes'>
                                            <span className='colValue'>
                                                Allele Number
                                            </span>
                                        </Tooltip>,
                                    children:
                                        <div style={{ width: '100%', fontSize: '18px', fontWeight: '600' }}>
                                            {yangyeDetail?.an ?? 0}
                                        </div>,
                                },
                                {
                                    label:
                                        <Tooltip placement="top" title='Alternate allele frequency in high quality genotypes'>
                                            <span className='colValue'>
                                                Allele Frequency
                                            </span>
                                        </Tooltip>,
                                    children:
                                        <div style={{ width: '100%', fontSize: '18px', fontWeight: '600' }}>
                                            {yangyeDetail?.an ? (yangyeDetail?.ac / yangyeDetail?.an).toFixed(4) : 0}
                                        </div>,
                                },
                                {
                                    label:
                                        <Tooltip placement="top" title='Number of individuals homozygous for alternate allele'>
                                            <span className='colValue'>
                                                Number of homozygotes hom_alt
                                            </span>
                                        </Tooltip>,
                                    children:
                                        <div style={{ width: '100%', fontSize: '18px', fontWeight: '600' }}>
                                            {yangyeDetail?.homAlt ?? 0}
                                        </div>,
                                },
                                {
                                    label:
                                        <Tooltip placement="top" title="Fraction of individuals with >30x coverage at this variant's locus">
                                            <span className='colValue'>
                                                Fraction of individuals with {'>'} 30x coverage
                                            </span>
                                        </Tooltip>,
                                    children:
                                        <div style={{ width: '100%', fontSize: '18px', fontWeight: '600' }}>
                                            100%
                                        </div>,
                                },
                            ]}
                        />
                    </Card>
                    {resultInfo === "chr13:20189473-C-T" &&
                        <Card >
                            <iframe src="http://101.126.24.36:3000/jbrowse/?config=ncaw%2Fconfig.json&assembly=hg19&loc=chr13:20184473..20194473&tracks=genecode_GRCh38,WBBC_Chinese_GRCh38,HRC_European_hg19Tohg38,jMorp_GRCh38_Japanese_norm,gnomAD_GRCh38,ChinaMAP_Chinese_GRCh38,dbSNP_freq_GRCh38"
                                style={{ height: "800px", width: "100%", border: "0px" }} />
                        </Card>
                    }

                </div>
                :
                <Card style={{ backgroundColor: 'aliceblue', padding: '2px 10px' }} >
                    <Skeleton active paragraph={{
                        rows: 9,
                    }} />
                </Card>
            }
            <Divider />
            <Row gutter={16}>
                <Col span={24} >
                    <span className='title_name' style={{ display: 'flex', alignItems: 'center', fontWeight: '700' }}>
                        <img src='https://gsrd-web-images.tos-cn-beijing.volces.com/gene10.png'
                            style={{ height: '30px', width: '30px', marginRight: '10px' }}
                        />
                        Reference
                    </span>
                    <Descriptions style={{ margin: '20px 0px' }} column={1}
                        bordered
                        size='small'
                        items={[
                            {
                                key: '2',
                                label: <span style={{ fontWeight: '600', fontSize: '18px' }}>Pubmed</span>,
                                children: <a style={{ fontSize: '16px', fontWeight: '600' }}
                                    href={'https://www.ncbi.nlm.nih.gov/pubmed/' + resultInfo?.VEP_PUBMED?.replaceAll("|", ",")}
                                    target="_blank"
                                >
                                    {resultInfo?.VEP_PUBMED?.replaceAll("|", ",") ?? '-'}
                                </a>,
                            },
                        ]} />
                </Col>
            </Row>
        </div>
    )
}
