import React, { useCallback, useState } from 'react'
import {
    Button,
    Checkbox,
    Form,
    Input,
    Select,
    Upload,
    Row,
    Col,
    message,
    Typography,
    Modal
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import axios from 'axios';
import API_ROUTES from '../../../api';


export default function HomeUpload({ onPageChange }) {

    const { Dragger } = Upload;
    const [uploadFileName, setUploadFileName] = useState("");
    const [messageApi, contextHolder] = message.useMessage();
    const { Paragraph, Title } = Typography;
    const [demoButtonLoading, setDemoButtonLoading] = useState(false)
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const uploadProps = {
        name: 'file',
        multiple: true,
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
        beforeUpload: (file) => {
            const isLt10M = file.size / 1024 / 1024 < 100;
            if (!isLt10M) {
                message.error('File must be smaller than 100MB!');
            }
            return isLt10M;
        },
    };

    const formFinishFunc = (value) => {
        messageApi.open({
            type: 'loading',
            content: 'Fetching...',
        });
        setSubmitButtonLoading(true)
        axios.post(API_ROUTES.api_gene_send, {
            uploadFileName: uploadFileName.uploadFileName,
            uploadFile: uploadFileName.uploadFile,
            taskName: value.taskName,
            referencePanel: value.ReferencePanel,
            mode: value.mode,
            chr: value.chr
        }, {
            headers: {
                Authorization: localStorage.getItem('userInfo'),
            },
        })
            .then(function (response) {
                if (response.data.code === 200) {
                    messageApi.open({
                        type: 'success',
                        content: 'Submit successfully!',
                    });
                    onPageChange('upload');
                } else {
                    messageApi.open({
                        type: 'error',
                        content: 'Submit fail! Please try again!',
                    });
                }
            })
            .catch(function (error) {
                messageApi.open({
                    type: 'error',
                    content: 'Submit fail! Please try again!',
                });
            })
            .finally(() => {
                setSubmitButtonLoading(false)
            })
    }

    const customRequestFunc = async ({ file, onSuccess, onError }) => {

        try {
            const formData = new FormData();
            formData.append('file', file);
            const response = await axios.post(API_ROUTES.api_xiaoai_upload, formData, {
                headers: {
                    Authorization: localStorage.getItem('userInfo'),
                },
            });
            if (response.data.code === 200) {
                setUploadFileName(response.data.data)
                onSuccess("ok");
            } else {
                onError("Upload failed");
            }
        } catch (error) {
            console.error('Upload error:', error);
            onError("Error occurred");
        }
    };

    return (
        <div style={{ padding: '0px 180px' }}>
            {contextHolder}
            <p style={{ color: 'rgb(44, 120, 150)', fontSize: '20px', lineHeight: '32px' }}>
                <Title level={3} style={{ fontWeight: '700' }}>
                    Genotype Imputation (Beagle5.4)
                </Title>
                <Paragraph style={{ fontSize: '18px', fontWeight: '500' }}>
                    This is the new GSRD-100K<sup style={{ fontSize: '12px' }}>WCH</sup> Imputation Server Pipeline using <span style={{ fontWeight: '600' }}>Beagle5.4</span>.<br />
                    If your input data is GRCh37/hg19 please ensure chromosomes are encoded without prefix (e.g. 20).<br />
                    If your input data is GRCh38/hg38 please ensure chromosomes are encoded with prefix 'chr' (e.g. chr20).
                </Paragraph></p>
            <Form
                layout='vertical'
                onFinish={formFinishFunc}
                initialValues={{
                    report: false,
                    mode: "1",
                }}
            >
                <Row style={{ margin: '30px 0px' }}>
                    <Col span={5}>
                        <Form.Item
                            label={(<div style={{ fontSize: '24px', fontWeight: '600' }}>Project Name</div>)}
                            name="taskName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please confirm projectName!',
                                },
                            ]}>
                            <Input placeholder="please Input project name" />
                        </Form.Item>
                    </Col>
                    <Col span={5} offset={1}>
                        <Form.Item
                            label={(<div style={{ fontSize: '24px', fontWeight: '600' }}>Reference Panel</div>)}
                            name="ReferencePanel"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please confirm ReferencePanel!',
                                },
                            ]}>
                            <Select placeholder="please choose Reference Panel">
                                <Select.Option value="2">GRCh38/hg38</Select.Option>
                                <Select.Option value="1">GRCh37/hg19</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={5} offset={1}>
                        <Form.Item
                            label={(<div style={{ fontSize: '24px', fontWeight: '600' }}>Chr</div>)}
                            name="chr"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please confirm Chr infomation!',
                                },
                            ]}>
                            <Select placeholder="please choose chr">
                                <Select.Option value="1">chr1</Select.Option>
                                <Select.Option value="2">chr2</Select.Option>
                                <Select.Option value="3">chr3</Select.Option>
                                <Select.Option value="4">chr4</Select.Option>
                                <Select.Option value="5">chr5</Select.Option>
                                <Select.Option value="6">chr6</Select.Option>
                                <Select.Option value="7">chr7</Select.Option>
                                <Select.Option value="8">chr8</Select.Option>
                                <Select.Option value="9">chr9</Select.Option>
                                <Select.Option value="10">chr10</Select.Option>
                                <Select.Option value="11">chr11</Select.Option>
                                <Select.Option value="12">chr12</Select.Option>
                                <Select.Option value="13">chr13</Select.Option>
                                <Select.Option value="14">chr14</Select.Option>
                                <Select.Option value="15">chr15</Select.Option>
                                <Select.Option value="16">chr16</Select.Option>
                                <Select.Option value="17">chr17</Select.Option>
                                <Select.Option value="18">chr18</Select.Option>
                                <Select.Option value="19">chr19</Select.Option>
                                <Select.Option value="20">chr20</Select.Option>
                                <Select.Option value="21">chr21</Select.Option>
                                <Select.Option value="22">chr22</Select.Option>
                            </Select>
                        </Form.Item></Col>
                    <Col span={5} offset={1}>
                        <Form.Item
                            label={(<div style={{ fontSize: '24px', fontWeight: '600' }}>Mode</div>)}
                            name="mode"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please confirm mode!',
                                },
                            ]}
                            defaultValue={"1"}
                        >
                            <Select placeholder="please choose mode" defaultValue={"1"}>
                                <Select.Option value="1">Phasing & lmputation</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item
                    label={(
                        <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                            <span style={{ fontSize: '24px', fontWeight: '600', marginRight: '20px' }}>
                                Upload
                            </span>
                            <Button
                                type="primary"
                                shape="round"
                                size="middle">
                                <a href='https://gsrd-web-images.tos-cn-beijing.volces.com/example_chr20_GRCh37.vcf.gz'>
                                    File Example In GRCh37
                                </a>
                            </Button>
                            <Button
                                type="primary"
                                shape="round"
                                size="middle"
                                style={{ marginLeft: '20px' }}>
                                <a href='https://gsrd-web-images.tos-cn-beijing.volces.com/example_chr20_GRCh38.vcf.gz'>
                                    File Example In GRCh38
                                </a>
                            </Button>
                            <Button
                                type="primary"
                                shape="round"
                                size="middle"
                                style={{ marginLeft: '20px' }}
                                loading={demoButtonLoading}
                                onClick={() => setIsModalOpen(true)}
                            >
                                Using Demo Data
                            </Button>
                            <Modal title={<Title level={4} style={{ fontWeight: '700' }}>
                                Click 'OK' to use test data
                            </Title>}
                                open={isModalOpen}
                                okButtonProps={{
                                    loading: demoButtonLoading
                                }}
                                onOk={() => {
                                    messageApi.open({
                                        type: 'loading',
                                        content: 'Fetching...',
                                    });
                                    setDemoButtonLoading(true)
                                    axios.post(API_ROUTES.api_gene_send, {
                                        testFlag: 1
                                    }, {
                                        headers: {
                                            Authorization: localStorage.getItem('userInfo'),
                                        },
                                    })
                                        .then(function (response) {
                                            if (response.data.code === 200) {
                                                messageApi.open({
                                                    type: 'success',
                                                    content: 'Submit successfully!',
                                                });
                                                onPageChange('upload');
                                            } else {
                                                messageApi.open({
                                                    type: 'error',
                                                    content: 'Submit fail! Please try again!',
                                                });
                                            }
                                        })
                                        .catch(function (error) {
                                            messageApi.open({
                                                type: 'error',
                                                content: 'Submit fail! Please try again!',
                                            });
                                        })
                                        .finally(() => {
                                            setDemoButtonLoading(false)
                                        })
                                }}
                                onCancel={() => setIsModalOpen(false)}>
                            </Modal>
                        </div>
                    )}
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    name="uploadFile"
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm file!',
                        },
                    ]}>

                    <Dragger {...uploadProps}
                        maxCount={1}
                        customRequest={customRequestFunc}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined style={{ fontSize: '60px' }} />
                        </p>
                        <p className="ant-upload-text" style={{ fontSize: '30px', fontWeight: '700' }}>Please submit a file with the suffix.vcf.gz</p>
                        <p className="ant-upload-text" >The file must be smaller than 50 megabytes.</p>
                    </Dragger>
                </Form.Item>

                <Form.Item name="report"
                    valuePropName="checked"
                    rules={[
                        {
                            validator: (_, value) =>
                                value ? Promise.resolve() : Promise.reject('Please confirm the agreement!'),
                        },
                    ]}
                    style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}
                >
                    <Checkbox style={{ fontSize: '16px', fontWeight: '600', marginLeft: '10px' }}>
                        I will report any inadvertent data release , security breach or other data management incident of which I become aware.
                    </Checkbox>
                </Form.Item>

                <Form.Item
                    style={{
                        display: "flex",
                        justifyContent: 'center',
                    }}
                >
                    <Button
                        type="primary"
                        size="large"
                        htmlType="submit"
                        style={{
                            width: '300px',
                            height: '60px',
                            color: '#FFFFFF',
                            borderRadius: '10px',
                            fontWeight: '800',
                            fontSize: '26px',
                            margin: '0px 10px'
                        }}
                        loading={submitButtonLoading}
                    >
                        Submit Project
                    </Button>

                </Form.Item>
            </Form>
        </div>
    )
}
