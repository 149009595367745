import React, { useState, useEffect } from 'react'
import { Menu, Row } from 'antd';
import { AppstoreOutlined, MailOutlined } from '@ant-design/icons';
import UserList from './UserList';
import UserInfo from './UserInfo';

export default function UserManage() {

  const [disabledFlag, setDisabledFlag] = useState(true)

  const items = [
    {
      label: 'User List',
      key: 'userlist',
      icon: <MailOutlined />,
    },
    {
      label: 'User Detail',
      key: 'userdetail',
      icon: <AppstoreOutlined />,
      disabled: disabledFlag,
    },
  ];

  const [current, setCurrent] = useState('userlist');

  const onClick = (e) => {
    if(e.key === 'userlist'){
      setDisabledFlag(true)
    }
    setCurrent(e.key);
  };

  useEffect(() => {
    if (localStorage.getItem('clickedUser')) {
      setDisabledFlag(false)
      setCurrent("userdetail")
    }
  }, []);

  const renderPage = () => {
    switch (current) {
      case 'userlist':
        localStorage.removeItem('clickedUser');
        return <UserList onPageChange={(data) => {
          setCurrent(data)
          setDisabledFlag(false)
        }} />;
      case 'userdetail':
        return <UserInfo />;
      default:
        return <div>No content</div>;
    }
  };

  return (
    <div>
      <Row style={{ marginBottom: '10px' }}>
        <Menu
          onClick={onClick}
          selectedKeys={[current]}
          mode="horizontal"
          items={items} />
      </Row>
      <Row>
        {renderPage()}
      </Row>
    </div>
  )
}
