import React, { useEffect, useState } from 'react';
import { Button, Col, Menu, Row } from 'antd';
import ManageReport from './ManageReport';
import UserManage from './UserManage';
import { useNavigate } from 'react-router-dom'

export default function Manage() {

    const [selectedPage, setSelectedPage] = useState('UserManage');

    const navigate = useNavigate();

    const handleMenuClick = (e) => {
        setSelectedPage(e.key);
    };

    // 根据选中的页面显示相应的组件
    const renderPage = () => {
        if (selectedPage === 'UserManage') {
            return <UserManage />;
        } else if (selectedPage === 'ManageReport') {
            return <ManageReport />;
        }
        // 如果没有匹配到页面，可以显示默认内容或者空白页
        return <div>No content</div>;
    };

    return (
        <div>
            <Row style={{ padding: "20px 0px" }}>
                <Col span={1} offset={22}>
                    <Button
                        type='primary'
                        onClick={() => {
                            navigate('/manageLogin')
                            localStorage.clear();
                        }}>
                        logout
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col span={4}>
                    <Menu
                        onClick={handleMenuClick}
                        style={{
                            width: 256,
                        }}
                        mode="inline"
                    >
                        <Menu.Item key="UserManage">用户管理</Menu.Item>
                        <Menu.Item key="ManageReport">报表查询</Menu.Item>
                    </Menu>
                </Col>
                <Col span={19}>{renderPage()}</Col>
                <Col span={1}>

                </Col>
            </Row>
        </div>
    );
}
