import React, { useState, useEffect } from 'react';
import { Row, Col, Menu, Typography } from 'antd';
import {
    FileSearchOutlined,
    TableOutlined,
    CloudUploadOutlined,
    ShareAltOutlined,
    CloudDownloadOutlined,
    CaretRightOutlined
} from '@ant-design/icons';

import { useLocation, useNavigate } from 'react-router-dom'
import HomeSearch from './HomeSearch';
import HomeSearchResult from './HomeSearchResult';
import HomeUpload from './HomeUpload';


export default function Home() {

    const location = useLocation();
    const initialPage = location.state?.pages;
    const { Text } = Typography;

    const navigate = useNavigate();
    const [downPage, setDownPage] = useState(initialPage || 'search');
    const [current, setCurrent] = useState(initialPage || 'search');

    useEffect(() => {
        const myData = localStorage.getItem('userInfo')
        if (myData) {
            console.log('Data exists in localStorage:', myData);
        } else {
            navigate('/login')
            console.log('Data does not exist in localStorage');
        }
    }, []);

    const itemsMenu = [
        {
            label: ' Create Project ',
            key: 'search',
            icon: <FileSearchOutlined style={{ fontSize: '24px' }} />,
        },
        {
            label: ' My Projects ',
            key: 'upload',
            icon: <TableOutlined style={{ fontSize: '24px' }} />,
        },
    ];

    const renderPage = () => {
        if (downPage === 'search') {
            return (<HomeUpload onPageChange={handlePageChange} />)
        } else if (downPage === 'upload') {
            return (<HomeSearchResult />)
        }
    }

    const handlePageChange = (page) => {
        setDownPage(page);
        setCurrent(page);
    };


    const menuClick = (e) => {
        setDownPage(e.key)
        setCurrent(e.key);
    };

    return (
        <div>
            <div style={{ height: '80vh' }}>
                <Row style={{ marginTop: '80px' }}>
                    <Col span={8} offset={2}>
                        <Menu
                            onClick={menuClick}
                            defaultSelectedKeys={'search'}
                            selectedKeys={[current]}
                            mode="horizontal"
                            items={itemsMenu}
                            style={{ fontSize: '24px', fontWeight: '700' }}
                        />
                    </Col>
                </Row>
                <div style={{ paddingBottom: '50px' }}>
                    {renderPage()}
                </div>
            </div>
            <Row style={{
                wordWrap: 'break-word',
                backgroundImage:'url(https://gsrd-web-images.tos-cn-beijing.volces.com/door.png)',
                backgroundPosition: 'center', // 背景图片居中
                backgroundSize: 'contain', // 背景图片等比例缩放以适应容器
                backgroundRepeat: 'no-repeat', // 背景图片不重复
                 width: '100%',
                display: "flex", justifyContent: 'space-between',
                height: '30vh',
                marginTop:'50px'
            }}
                justify="center" align="middle"
            >
                <Col style={{ marginLeft: '200px' }}>
                    <CloudUploadOutlined
                        style={{
                            color: '#055878',
                            fontSize: '60px',
                            lineHeight: '60px',
                            verticalAlign: 'middle'
                        }}
                    />
                    <Text style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        lineHeight: '24px',
                        marginLeft: '20px',
                        fontSize: '18px',
                        color:'#055878',
                        maxWidth: '320px',
                        fontWeight:'600'
                    }}>
                        Upload your genotypes to your server.<br />
                        All interaction with the server are secured.
                    </Text>
                </Col>

                <Col>
                    <ShareAltOutlined
                        style={{
                            color: '#055878',
                            fontSize: '60px',
                            lineHeight: '60px',
                            verticalAlign: 'middle'
                        }} />
                    <Text style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        lineHeight: '24px',
                        marginLeft: '20px',
                        fontSize: '18px',
                        color:'#055878',
                        maxWidth: '320px',
                        fontWeight:'600'
                    }}>
                        Choose a reference panel.<br />
                        We will take care of pre-phasing and imputaion.
                    </Text>
                </Col>

                <Col style={{ marginRight: '200px' }}>
                    <CloudDownloadOutlined
                        style={{
                            color: '#055878',
                            fontSize: '60px',
                            lineHeight: '60px',
                            verticalAlign: 'middle'
                        }} />
                    <Text style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        lineHeight: '24px',
                        marginLeft: '20px',
                        fontSize: '18px',
                        color: "#055878",
                        maxWidth: '320px',
                        fontWeight:'600'
                    }}>
                        Download the results.<br />
                        After 14 days , all results are deleted from our server.
                    </Text>
                </Col>
            </Row>
        </div>
    );

}
