import './index.css';
import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Space, Modal, Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom'
import API_ROUTES from '../../api';
import axios from 'axios';


export default function ResetPwd() {

    const navigate = useNavigate();
    const [isDisabled, setIsDisabled] = useState(false);
    const [countdown, setCountdown] = useState(
        localStorage.getItem('countdown') ? JSON.parse(localStorage.getItem('countdown')) : 0
    );

    const onFinish = (values) => {
        axios.post(API_ROUTES.api_change_pwd, {
            password: values.password,
            secretKey: values.secretKey
        }, {
            headers: {
                Authorization: localStorage.getItem('userInfo')
            }
        }).then(function (response) {
            if (response.data.code === 500) {
                Modal.error({
                    content: response.data.msg
                });
            } else {
                Modal.success({
                    content: "change sccess!"
                });
                localStorage.clear()
                navigate('/login')
            }
        })
            .catch(function (error) {
                navigate('/login')
                Modal.error({
                    content: error.response.data.msgDes
                });
            })

    };

    const handleButtonClick = () => {
        axios
            .post(API_ROUTES.api_auth, {
                email: localStorage.getItem('email'),
            })
            .then(function (response) {
                Modal.success({
                    content: 'Email send success!'
                });
            })
            .catch(function (error) {
                Modal.error({
                    content: 'Email send fail!'
                });
                setIsDisabled(false);
                localStorage.removeItem('countdown');
            });

        setCountdown(10);
        setIsDisabled(true);
    };

    useEffect(() => {
        let timer;
        if (countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prevCountdown) => {
                    localStorage.setItem('countdown', JSON.stringify(prevCountdown - 1));
                    return prevCountdown - 1;
                });
            }, 1000);
        } else {
            setIsDisabled(false);
            localStorage.removeItem('countdown');
        }

        return () => {
            clearInterval(timer);
        };
    }, [countdown]);

    return (
        <div style={{ marginTop: '140px' }}>
            <span style={{
                fontSize: '60px',
                fontWeight: '700',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '60px'
            }}>
                Register
            </span>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Form
                    id="regist_form"
                    name="login"
                    style={{
                        width: '38.2%',
                    }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                >
                    <Row>
                        <Col span={17}>
                            <Form.Item
                                label={(
                                    <div style={{ fontSize: '24px', fontWeight: '600' }}>
                                        Secret key
                                    </div>
                                )}
                                name="secretKey"
                                rules={[{
                                    required: true,
                                    message: 'Please input your Secret key!'
                                }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6} offset={1}>
                                <Button
                                    onClick={handleButtonClick}
                                    disabled={isDisabled}
                                    type="primary"
                                    style={{ width: '100%' }}
                                >

                                    {isDisabled ? `Sending in ${countdown}s` : 'Get secretKey'}
                                </Button>
                        </Col>
                    </Row>
                    <Form.Item
                        label={(
                            <div style={{ fontSize: '24px', fontWeight: '600' }}>
                                Password
                            </div>
                        )}
                        name="password"
                        rules={[{
                            required: true,
                            message: 'Please input your password!'
                        }]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        label={(
                            <div style={{ fontSize: '24px', fontWeight: '600' }}>
                                Confirm Password
                            </div>
                        )}
                        name="confirmPassword"
                        dependencies={['password']}
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!'
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject('The two passwords do not match!');
                                }
                            })
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>


                    <Form.Item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button
                            type="primary"
                            size="large"
                            htmlType="submit"
                            style={{
                                width: '200px',
                                height: '60px',
                                color: '#FFFFFF',
                                borderRadius: '40px',
                                fontWeight: '800',
                                fontSize: '26px',
                                marginTop: '30px'
                            }}
                        >
                            Sumbit
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}
