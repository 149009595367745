import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import EchartsReact from 'echarts-for-react';
import mapEn from './mapEn.json';
const EchartsComponent = ({ selectedRegions, setSelectedRegions }) => {
    const initialModes = [
        { name: 'Northeast', value: "R1", key: 'R1' },
        { name: 'East', value: "R2", key: 'R2' },
        { name: 'North', value: "R3", key: 'R3' },
        { name: 'Central', value: "R4", key: 'R4' },
        { name: 'South', value: "R5", key: 'R5' },
        { name: 'Northwest', value: "R6", key: 'R6' },
        { name: 'Southwest', value: "R7", key: 'R7' },
        {
            name: "南海诸岛",
            value: 0,
            key: '0',
            itemStyle: {
                opacity: 0, // 直接设置为 0 隐藏该区域
            },
            label: {
                show: false // 隐藏标签
            }
        }
    ];

    const [selectedMode, setSelectedMode] = useState(
        initialModes.map(mode => ({
            ...mode,
            itemStyle: mode.itemStyle || {
                color: '#000000',
                areaColor: "#13769a",
                borderColor: "#000000"
            },
            label: mode.label || {
                fontSize: 12,
                color: "#fff",
            },
            ...(mode.name !== "南海诸岛" && { choosed: true })
        }))
    );

    useEffect(() => {
        // 更新EchartsComponent的选中状态
        const updatedSelectedMode = initialModes.map(region => ({
            ...region,
            itemStyle: region.name === "南海诸岛"
                ? region.itemStyle // 保留南海诸岛的 itemStyle
                : selectedRegions.includes(region.value)
                    ? { color: '#000000', areaColor: "#13769a", borderColor: "#000000" }
                    : undefined,
            label: region.name === "南海诸岛"
                ? region.label // 保留南海诸岛的 label
                : {
                    fontSize: 12,
                    color: selectedRegions.includes(region.value) ? "#fff" : "#000",
                },
            choosed: selectedRegions.includes(region.value),
        }));

        setSelectedMode(updatedSelectedMode);
    }, [selectedRegions]);


    const mainchart = useRef(null);

    echarts.registerMap("china", mapEn);

    const option = {
        geo: {
            map: "china",
            silent: true,
            regions: [
                {
                    name: "南海诸岛",
                    seleted: false,
                    itemStyle: {
                        opacity: 0, // 直接设置为 0 隐藏该区域
                    },
                    label: {
                        show: false // 隐藏标签
                    }
                }
            ],
        },
        series: [
            {
                type: "map",
                map: "china",
                label: {
                    show: true
                },
                selectedMode: 'multiple', // 启用多选
                data: selectedMode,
            },
        ],
    };

    const toggleRegion = (data) => {
        const updatedSelectedRegions = selectedRegions.includes(data.value)
            ? selectedRegions.filter(region => region !== data.value)
            : [...selectedRegions, data.value];

        setSelectedRegions(updatedSelectedRegions);
    };

    return (
        <div>
            <EchartsReact
                ref={mainchart}
                option={option}
                onEvents={{
                    'click': (params) => {
                        const { data } = params;
                        if (data.name === '南海诸岛') {
                            // 设置南海诸岛的属性
                            const updatedSelectedMode = selectedMode.map(region => {
                                if (region.name === '南海诸岛') {
                                    return {
                                        ...region,
                                        itemStyle: {
                                            opacity: 0, // 隐藏该区域
                                        },
                                        label: {
                                            show: false // 隐藏标签
                                        }
                                    };
                                }
                                return region;
                            });
                            setSelectedMode(updatedSelectedMode); // 更新选中状态
                            return; // 结束点击事件
                        }
                        toggleRegion(data);
                    }
                }}
                style={{ width: '750px', height: '750px' }}
            />
        </div>
    );
};

export default EchartsComponent;
