import axios from 'axios';
import React, { useEffect, useState } from 'react'
import API_ROUTES from '../../../../api';
import { Button, Modal, Row, Image, Descriptions, Form, Col, Radio, Input } from 'antd';

export default function UserInfo() {

    const { TextArea } = Input;

    const [userInfo, setUserInfo] = useState({})
    const [disabledFlag, setDisabledFlag] = useState(false)

    useEffect(() => {

        axios.post(API_ROUTES.api_get_user_info, null, {
            params: {
                id: localStorage.getItem('clickedUser'),
            },
            headers: {
                "Authorization": localStorage.getItem('userInfo')
            }
        })
            .then(function (response) {
                if (response.data.code === 500) {
                    Modal.error({
                        content: response.data.msg
                    });
                } else {
                    setUserInfo(response.data.data)
                    if (response.data.data.uploadStatus === 1) {
                        setDisabledFlag(true)
                    }
                }
            })
            .catch(function (error) {
                Modal.error({
                    content: error.response.data.msgDes
                });
            })
    }, []);

    const items = [
        {
            key: '1',
            label: 'UserName',
            children: userInfo.name,
        },
        {
            key: '2',
            label: 'organization',
            children: userInfo.org,
        },
        {
            key: '3',
            label: 'upload status',
            children: userInfo.uploadStatus === 0 ? '未提交' :
                userInfo.uploadStatus === 1 ? '已通过' :
                    userInfo.uploadStatus === 2 ? '未通过' :
                        userInfo.uploadStatus === 3 ? '等待审核' :
                            ''
        },
        {
            key: '4',
            label: 'Front of ID card',
            children: <Image
                width={200}
                src={userInfo.idCard}
            />,
        },
        {
            key: '5',
            label: 'Back of ID card',
            children: <Image
                width={200}
                src={userInfo.idCardBack}
            />,
        },
        {
            key: '6',
            label: 'Student Certificate',
            children: <Image
                width={200}
                src={userInfo.studentCertificate}
            />,
        },
    ];

    return (
        <div>
            <Row style={{ margin: '10px 0px 30px 0px' }}>
                <Descriptions title={userInfo.email} layout="vertical" items={items} />
            </Row>
            <Row >
                <Col span={8} offset={6}>
                    <Form onFinish={
                        (values) => {
                            axios.post(API_ROUTES.api_audit_image, {
                                id: localStorage.getItem('clickedUser'),
                                auditResult: values.auditResult,
                                opinion: values.opinion
                            }, {
                                headers: {
                                    "Authorization": localStorage.getItem('userInfo')
                                }
                            })
                            .then(function (response) {
                                if (response.data.code === 500) {
                                    Modal.error({
                                        content: response.data.msg
                                    });
                                } else {
                                    Modal.success({
                                        content:'Audit success!'
                                    })
                                }
                            })
                            .catch(function (error) {
                                Modal.error({
                                    content: error.response.data.msgDes
                                });
                            })
                        }}>
                        <Form.Item
                            name="auditResult"
                            label="Audit Result"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your username!',
                                },
                            ]}>
                            <Radio.Group disabled={disabledFlag}>
                                <Radio value={1}>Pass</Radio>
                                <Radio value={2}>Reject</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item name="opinion" label="opinion">
                            <TextArea rows={4} placeholder='please input your oponion' disabled={disabledFlag} />
                        </Form.Item>
                        <Form.Item
                            wrapperCol={{
                                offset: 10,
                                span: 16,
                            }}
                        >
                            <Button type="primary" htmlType="submit" disabled={disabledFlag}>
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </div >
    )
}
