const API_BASE_URL = '/api'

const API_ROUTES = {

    //获取结果
    api_get : `${API_BASE_URL}/gene/get`,

    //重新发送
    api_resend : `${API_BASE_URL}/gene/resend`,

    //获取列表
    api_list : `${API_BASE_URL}/gene/list`,

    //登录
    api_login : `${API_BASE_URL}/xiaoai/login`,

    //注册
    api_regist : `${API_BASE_URL}/xiaoai/regist`,

    //获取验证
    api_auth : `${API_BASE_URL}/xiaoai/auth`,

    //获取用户列表
    api_user_list : `${API_BASE_URL}/manage/list`,

    //管理员登录
    api_manage_login : `${API_BASE_URL}/manage/login`,

    //审核
    api_manage_audit : `${API_BASE_URL}/manage/audit`,

    //修改用户信息
    api_manage_update: `${API_BASE_URL}/manage/update`,

    //用户自己修改信息
    api_upload_user:`${API_BASE_URL}/xiaoai/upload_user`,

    //获取用户详情
    api_get_user_info:`${API_BASE_URL}/manage/get_user_info`,

    //审核用户图片
    api_audit_image:`${API_BASE_URL}/manage/audit_image`,

    //用户自行修改账号密码
    api_change_pwd:`${API_BASE_URL}/xiaoai/change_pwd`,

    //查询信息
    api_gene_send:`${API_BASE_URL}/gene/send`,

    //上传照片
    api_xiaoai_upload:`${API_BASE_URL}/xiaoai/upload`,

    //查询查询的列表
    api_imputation_list:`${API_BASE_URL}/imputation/list`,

    //查询任务结果
    api_imputation_get:`${API_BASE_URL}/imputation/get`,

    //删除任务
    api_imputation_delete: `${API_BASE_URL}/imputation/delete`,

    //获取当前查询条数
    api_imputation_getCount:`${API_BASE_URL}/imputation/getCount`,

    //忘记密码
    api_xiaoai_forget:`${API_BASE_URL}/xiaoai/forget`,

    //查询search
    api_search_searchOne:`${API_BASE_URL}/search/searchOne`,

    //当前人的查询列表
    api_search_searchList:`${API_BASE_URL}/search/searchList`,

    //获取解析结果：
    api_search_parseResult:`${API_BASE_URL}/search/parseResult`,

    //获取解析结果2：
    api_search_parseResultInfo:`${API_BASE_URL}/search/parseResultInfo`,

  
}

export default API_ROUTES;