import React from 'react'
import { Form, Input, Button, Checkbox, Space, Modal, Row, Col } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import API_ROUTES from '../../api';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function ManageLogin() {

  const navigate = useNavigate();

  const onFinish = (values) => {
    axios
      .post(API_ROUTES.api_manage_login, {
        userName: values.email,
        password: values.password,
      })
      .then(function (response) {
        if (response.data.code === 500) {
          Modal.error({
            content: response.data.msg
          });
        } else {
          Modal.success({
            content: 'Login success!'
          });
          //将返回结果保存到local storage
          localStorage.setItem('userInfo', response.data.data.userInfo)
          localStorage.setItem("email", response.data.data.email)
          localStorage.setItem('id', response.data.data.id)
          navigate('/manage')
        }
      })
      .catch(function (error) {
        Modal.error({
          content: error
        });
      });
  }


  const [form] = Form.useForm();

  return (
    <div id="regist_div" >
      <Row style={{ padding: '20px 0px 180px 0px' }}>
        <Col span={2} offset={22}>
          <Button type='primary' onClick={() => {
            navigate('/login')
          }}>Go to user login</Button>
        </Col>
      </Row>
      <Row style={{ marginBottom: '30px' }}>
        <Col span={6} offset={9}>
          <span style={{ fontSize: '30px' }}>
            Manage Login
          </span>
        </Col>
      </Row>
      <Row>
        <Col span={6} offset={9}>
          <Form
            id="regist_form"
            name="login"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            form={form}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[{
                required: true,
                message: 'Please input your Email adress!'
              }]}
            >
              <Input placeholder='Email' prefix={<UserOutlined className="site-form-item-icon" />} />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[{
                required: true,
                message: 'Please input your password!'
              }]}
            >
              <Input.Password />
            </Form.Item>
            <Col span={6} offset={10}>
              <Form.Item>
                <Space wrap>
                  <Button type="primary" htmlType="submit" style={{ padding: '4px 30px' }}>
                    Login
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Form>
        </Col>
      </Row>
      <Row style={{ height: '40vh' }}>

      </Row>
    </div>
  )
}
