import { Row, Descriptions, Table, Divider, Badge, Col } from 'antd'
import React, { useState, useEffect } from 'react'
import './index.css'
import { TableOutlined, BorderlessTableOutlined } from '@ant-design/icons'
export default function SearchTwo({ resultInfo }) {



    const dbsnfpItem = () => {
        const initialItems = [
            {
                key: '1',
                label: labelInfo("Phylop"),
                children: childrenInfo(resultInfo.VEP_PolyPhen?.split('|')[0] && resultInfo.VEP_PolyPhen?.split('|')[0] !== "" ?
                    resultInfo.VEP_PolyPhen?.split('|')[0] :
                    '-')
            }, {
                key: '2',
                label: labelInfo("CADD"),
                children: childrenInfo(resultInfo.DBSNFP?.DBNSFP_CADD_PHRED ?? '-')
            },
            {
                key: '8',
                label: labelInfo("AlphaMissense"),
                children: childrenInfo(resultInfo.DBSNFP?.DBNSFP_AlphaMissense_score ?? '-'),
            },
            {
                key: '9',
                label: labelInfo("EVE"),
                children: childrenInfo(resultInfo.DBSNFP?.DBNSFP_EVE_score ?? '-'),
            }, {
                key: '5',
                label: labelInfo("PolyPhen-2 HDIV"),
                children: childrenInfo(resultInfo.DBSNFP?.DBNSFP_POLYPHEN2_HDIV_SCORE ?? '-'),
            }, {
                key: '6',
                label: labelInfo("PolyPhen-2 HVAR"),
                children: childrenInfo(resultInfo.DBSNFP?.DBNSFP_POLYPHEN2_HVAR_SCORE ?? '-'),
            }, {
                key: '3',
                label: labelInfo("SIFT"),
                children: childrenInfo(resultInfo.DBSNFP?.DBNSFP_SIFT_SCORE ?? '-'),
            }, {
                key: '7',
                label: labelInfo("GERP"),
                children: childrenInfo(resultInfo.DBSNFP?.DBNSFP_GERP_RS ?? '-'),
                span: 2
            }, {
                key: '4',
                label: labelInfo("REVEL"),
                children: childrenInfo(resultInfo.DBSNFP?.DBNSFP_REVEL_SCORE ?? '-'),
            }, {
                key: '13',
                label: labelInfo("MutScore"),
                children: childrenInfo(resultInfo.DBSNFP?.DBNSFP_MutScore_score ?? '-'),
            },
            {
                key: '9',
                label: labelInfo("SpliceAI Acceptor Loss"),
                children: childrenInfo(resultInfo.SpliceAI?.SpliceAI_pred_DS_AL ?? '-'),
            },
            {
                key: '10',
                label: labelInfo("SpliceAI Donor Loss"),
                children: childrenInfo(resultInfo.SpliceAI?.SpliceAI_pred_DS_DL ?? '-'),
            },
            {
                key: '11',
                label: labelInfo("SpliceAI Acceptor Gain"),
                children: childrenInfo(resultInfo.SpliceAI?.SpliceAI_pred_DS_AG ?? '-'),
            },
            {
                key: '12',
                label: labelInfo("SpliceAI Donor Gain"),
                children: childrenInfo(resultInfo.SpliceAI?.SpliceAI_pred_DS_DG ?? '-'),
            },

        ];
        return initialItems;
    };

    const rsId = resultInfo?.VEP_Existing_variation?.split("|")[0]?.split("&")[0];
    const dataSourceInfo = () => {
        if (resultInfo) {
            const listRes = resultInfo?.vepList || [];
            return listRes.map((item, index) => ({
                key: index,
                dbsnp: item.VEP_Existing_variation,
                af: resultInfo?.AN ? (resultInfo.AC / resultInfo.AN).toFixed(4) : '0.0000',
                ac: resultInfo.AC,
                an: resultInfo.AN,
                symbol: item.VEP_SYMBOL,
                hgvsc: item.VEP_HGVSc,
                hgvsConsequence: item.VEP_HGVSp?.split(':')[0],
                protein: item.VEP_HGVSp ? item.VEP_HGVSp.split(':').length > 1 ? item.VEP_HGVSp.split(':')[1] : '' : '',
                select: item.VEP_MANE_SELECT,
                transcript: item.VEP_Feature,
                cDNA: item.VEP_HGVSc ? item.VEP_HGVSc.split(':').length > 1 ? item.VEP_HGVSc.split(':')[1] : '' : '',
                consequence: item.VEP_Consequence,
                sift: item.VEP_SIFT,
                polyphen: item.Polyphen,
                pubmed: item.VEP_PUBMED,
                vepPick: item.VEP_PICK
            }));
        } else {
            return [];
        }
    }

    const tableTitle = (value) => {
        return (<div style={{ fontSize: '18px' }}>
            {value}
        </div>
        )
    }

    const labelInfo = (value) => {
        return (<div style={{ fontSize: '18px', fontWeight: '600' }}>
            {value}
        </div>)
    }

    const childrenInfo = (value) => {
        return (<div style={{ fontSize: '16px', fontWeight: '600' }}>
            {value}
        </div>)
    }

    const tableValue = (text) => {
        return (
            <span style={{ fontSize: '16px' }}>
                {text}
            </span>
        )
    }

    return (
        <div>
            <Divider />
            <span className='title_name' style={{ display: 'flex', alignItems: 'center' }}>
                <img src='https://gsrd-web-images.tos-cn-beijing.volces.com/gene2.png'
                    style={{ height: '30px', width: '30px', marginRight: '10px' }}
                />
                Variants Information
            </span>
            <Row style={{ margin: '30px 50px', display: "flex", alignItems: 'center', justifyContent: 'space-between' }}  >
                <div>
                    <span className='title_name_sub' >
                        Genomic Position (GRCh38):
                    </span>
                    <Badge dot>
                        <a className='content_name'
                            target="_blank"
                            href={'https://genome.ucsc.edu/cgi-bin/hgTracks?db=hg38&lastVirtModeType=default&lastVirtModeExtraState=&virtModeType=default&virtMode=0&nonVirtPosition=&position=chr' + resultInfo?.searchInfo.searchChr + ':' + (resultInfo?.searchInfo.searchPos - 25) + '-' + (resultInfo?.searchInfo.searchPos + 25)}>
                            {resultInfo?.searchInfo.searchChr
                                + ':' +
                                resultInfo?.searchInfo.searchPos}
                        </a>
                    </Badge>
                </div>
                <div>
                    <span className='title_name_sub' >
                        dbSNP:
                    </span>
                    <Badge dot>
                        <a href={'https://www.ncbi.nlm.nih.gov/snp/' + rsId}
                            target="_blank"
                            style={{
                                fontSize: '18px',
                                backgroundColor: 'aliceblue',
                                margin: '6px',
                                padding: '2px 5px',
                                borderRadius: '5px',
                                color: '#007799',
                            }}
                        >
                            {rsId}
                        </a>
                    </Badge>
                </div>
                <div>
                    <span className='title_name_sub' >
                        Genome Browser:
                    </span>
                    <Badge dot>
                        <a href={'http://genome.ucsc.edu/cgi-bin/hgSearch?search=' + rsId + '&db=hg38'}
                            target="_blank"
                            style={{
                                fontSize: '18px',
                                backgroundColor: 'aliceblue',
                                margin: '6px',
                                padding: '2px 5px',
                                borderRadius: '5px',
                                color: '#007799'
                            }}
                        >
                            {rsId}
                        </a>
                    </Badge>
                </div>
                <div>
                    <span className='title_name_sub' >
                        LitVar:
                    </span>
                    <Badge dot>
                        <a href={'https://www.ncbi.nlm.nih.gov/research/litvar2/docsum?variant=litvar@' + rsId + '%23%23&query=' + rsId}
                            target="_blank"
                            style={{
                                fontSize: '18px',
                                backgroundColor: 'aliceblue',
                                margin: '6px',
                                padding: '2px 5px',
                                borderRadius: '5px',
                                color: '#007799'
                            }}
                        >
                            {rsId}
                        </a>
                    </Badge>
                </div>
            </Row >


            <Table
                size='small'
                scroll={{
                    x: 'calc(200px + 100%)',
                }}
                dataSource={dataSourceInfo()}
                onRow={(record, index) => {
                    // 检查特定字段值，设置高亮
                    const highlightClassName = record.vepPick === "1" ? 'highlight-row' : '';
                    return {
                        className: `${highlightClassName}`,
                    };
                }}
                columns={[
                    {
                        title: tableTitle('Transcript'),
                        dataIndex: 'transcript',
                        key: 'transcript',
                        render: (value) => tableValue(value)
                    },
                    {
                        title: tableTitle('Symbol'),
                        dataIndex: 'symbol',
                        key: 'symbol',
                        render: (text) => (
                            <span style={{ fontStyle: 'italic', fontSize: '16px' }}>
                                {text}
                            </span>
                        )
                    },
                    {
                        title: tableTitle('AF'),
                        dataIndex: 'af',
                        key: 'af',
                        render: (value) => tableValue(value)
                    },
                    {
                        title: tableTitle('AC'),
                        dataIndex: 'ac',
                        key: 'ac',
                        render: (value) => tableValue(value)
                    },
                    {
                        title: tableTitle('AN'),
                        dataIndex: 'an',
                        key: 'an',
                        render: (value) => tableValue(value)
                    },
                    {
                        title: tableTitle('cDNA'),
                        dataIndex: 'cDNA',
                        key: 'cDNA',
                        render: (value) => tableValue(value)
                    },
                    {
                        title: tableTitle('protein'),
                        dataIndex: 'protein',
                        key: 'protein',
                        render: (value) => tableValue(value)
                    },
                    {
                        title: tableTitle('Consequence'),
                        dataIndex: 'consequence',
                        key: 'Consequence',
                        render: (value) => tableValue(value)
                    },
                    {
                        title: tableTitle('MANE Select Transcrip'),
                        dataIndex: 'select',
                        key: 'select',
                        render: (value) => tableValue(value)
                    }
                ]}
            />
            <Divider />
            <Row gutter={16}>
                <Col span={8} >
                    <span className='title_name' style={{ display: 'flex', alignItems: 'center', fontWeight: '700' }}>
                        <img src='https://gsrd-web-images.tos-cn-beijing.volces.com/gene5.png'
                            style={{ height: '30px', width: '30px', marginRight: '10px' }}
                        />
                        Pathogenicity Classification
                    </span>
                    <Descriptions style={{ margin: '20px 0px' }} column={1}
                        bordered
                        size='small'
                        items={[
                            {
                                key: '2',
                                label: labelInfo("HGMD"),
                                children: childrenInfo(resultInfo.HGMD?.CLASS === 'DM' ? 'Pathogenic' :
                                    resultInfo.HGMD?.CLASS === 'DM?' ? 'Likely Pathogenic' :
                                        '-'),
                            },
                            {
                                key: '3',
                                label: labelInfo("ClinVar"),
                                children: childrenInfo(resultInfo.clinvar_CLNSIG?.replaceAll('|', ';') ?? '-'),
                            },
                        ]} />
                </Col>
                <Col span={16}>
                    <span className='title_name' style={{ display: 'flex', alignItems: 'center', fontWeight: '700' }}>
                        <img src='https://gsrd-web-images.tos-cn-beijing.volces.com/gene6.png'
                            style={{ height: '26px', width: '26px', marginRight: '10px' }}
                        />
                        Prediction Software
                    </span>
                    <Descriptions style={{ margin: '20px 0px' }} column={2}
                        size='small'
                        bordered
                        items={dbsnfpItem()} />
                </Col>
                <Col span={24} >
                    <span className='title_name' style={{ display: 'flex', alignItems: 'center', fontWeight: '700' }}>
                        <img src='https://gsrd-web-images.tos-cn-beijing.volces.com/gene7.png'
                            style={{ height: '30px', width: '30px', marginRight: '10px' }}
                        />
                        Phenotype
                    </span>
                    <Descriptions style={{ margin: '20px 0px' }} column={1}
                        bordered
                        size='small'
                        items={[
                            {
                                key: '2',
                                label: labelInfo("HGMD"),
                                children: childrenInfo(resultInfo.HGMD?.PHEN?.replaceAll('"', '') ?? '-'),
                            },
                            {
                                key: '3',
                                label: labelInfo("ClinVar"),
                                children: childrenInfo(resultInfo.clinvar_CLNDN?.replaceAll('|', ';') ?? '-'),
                            },
                        ]} />
                </Col>
            </Row>
            <Divider />
            <span className='title_name' style={{ display: 'flex', alignItems: 'center', fontWeight: '700' }}>
                <img src='https://gsrd-web-images.tos-cn-beijing.volces.com/gene8.png'
                    style={{ height: '30px', width: '30px', marginRight: '10px' }}
                />
                Frequencies Information
            </span>
            <Table
                size='small'
                // style={{height:'500px'}}
                columns={[
                    {
                        title: tableTitle('Source'),
                        dataIndex: 'name',
                        key: 'name',
                        render: (value) => tableValue(value)
                    },
                    {
                        title: tableTitle('Allele Count'),
                        dataIndex: 'AC',
                        key: 'AC',
                        render: (value) => tableValue(value)
                    },
                    {
                        title: tableTitle('Allele Number'),
                        dataIndex: 'AN',
                        key: 'AN',
                        render: (value) => tableValue(value)
                    },
                    {
                        title: tableTitle('Allele Frequency'),
                        dataIndex: 'AF',
                        key: 'AF',
                        render: (value) => tableValue(value)
                    },
                    {
                        title: tableTitle('Number of Homozygotes'),
                        dataIndex: 'Hom',
                        key: 'Hom',
                        render: (value) => tableValue(value)
                    },
                ]}
                scroll={{
                    y: '500px'
                }}
                dataSource={resultInfo?.frequenciesList?.sort((a, b) => {
                    // 检查 a.name 是否以 'g' 或 'G' 开头
                    const isGA = a.name.toLowerCase().startsWith('g');
                    const isGB = b.name.toLowerCase().startsWith('g');
                    
                    // 如果 a.name 以 'g' 开头，而 b.name 不是，则 a 应该排在前面
                    if (isGA && !isGB) {
                      return -1;
                    }
                    // 如果 b.name 以 'g' 开头，而 a.name 不是，则 b 应该排在前面
                    if (!isGA && isGB) {
                      return 1;
                    }
                    // 如果两者都以 'g' 开头或都不是，则按字母顺序排序
                    if (isGA && isGB) {
                      return a.name.localeCompare(b.name);
                    }
                    // 如果两者都不以 'g' 开头，则按字母顺序排序
                    return a.name.localeCompare(b.name);
                  })
                  }
            />
        </div >
    )
}
