import {
    ConfigProvider, Button, Row, Statistic, Typography, Modal
} from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import CountUp from 'react-countup';
import {
    CloudUploadOutlined,
    ShareAltOutlined,
    CloudDownloadOutlined,
    CaretRightOutlined
} from '@ant-design/icons';
import axios from 'axios';
import API_ROUTES from '../../api';



export default function Display({ setKey }) {

    const navigate = useNavigate()
    const { Title, Paragraph } = Typography;
    const [runningCount, setRunningCout] = useState(0)

    // useEffect(() => {
    //     getCountInfo()
    // }, []);

    // const getCountInfo = () => {
    //     axios.get(API_ROUTES.api_imputation_getCount, {
    //         headers: {
    //             "Authorization": localStorage.getItem('userInfo')
    //         }
    //     })
    //         .then(function (response) {
    //             if (response.data.code === 500) {
    //                 Modal.error({
    //                     content: response.data.msg
    //                 });
    //             } else {
    //                 setRunningCout(response.data.data);
    //             }
    //         })
    //         .catch(function (error) {
    //             Modal.error({
    //                 content: error.response.data.msgDes
    //             });
    //         })
    //         .finally(function () {
    //         })
    // }

    const styleContent = (imageUrl, valueInfo, title, size) => {
        return (<div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '15vh'
        }}>
            <div style={{
                backgroundColor: 'white',
                borderRadius: '50%',
                boxShadow: '10px 10px 5px 0px rgba(0, 0, 0, 0.2)',
                width: '130px',
                height: '130px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <img src={imageUrl} style={{ width: size ?? '60px' }} />
            </div>
            <ConfigProvider
                theme={{
                    components: {
                        Statistic: {
                            contentFontSize: '42px',
                            colorTextHeading: 'white'
                        },
                    },
                }}
            >
                <Statistic
                    value={valueInfo}
                    precision={1}
                    formatter={(value) => <CountUp end={value} separator="," />}
                    style={{
                        textAlign: 'center',
                        color: 'white',
                        marginTop: '10px'
                    }}
                />
            </ConfigProvider>
            <span style={{
                fontWeight: '600',
                color: 'white',
                fontSize: '20px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '5vh',
                padding: '0 10px'
            }} dangerouslySetInnerHTML={{ __html: title }} />
        </div>)
    }

    const styleContentHis = (imageUrl, valueInfo, title) => {
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <div style={{
                backgroundColor: 'white',
                borderRadius: '50%',
                boxShadow: '10px 10px 5px 0px rgba(0, 0, 0, 0.2)',
                width: '160px',
                height: '160px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <ConfigProvider
                    theme={{
                        components: {
                            Statistic: {
                                contentFontSize: '28px',
                            },
                        },
                    }}
                >
                    <Statistic
                        value={valueInfo}
                        precision={2}
                        formatter={(value) => <CountUp end={value} separator="," />}
                        style={{
                            fontSize: '32px',
                            textAlign: 'center'
                        }}
                    />
                </ConfigProvider>
            </div>
            <span style={{
                fontWeight: '800',
                marginTop: '10px',
                color: 'white',
                fontSize: '30px'
            }}>{title}</span>
        </div>
    }


    return (
        <div>
            <img src={"https://gsrd-web-images.tos-cn-beijing.volces.com/righttop.png"}
                style={{
                    zIndex: '1', position: 'fixed', top: '0', width: '30%'
                }} />

            <img src={"https://gsrd-web-images.tos-cn-beijing.volces.com/lefttop.png"}
                style={{
                    zIndex: '2', position: 'fixed', right: '0', bottom: '0'
                    , width: '400px', height: '300px'
                }} />
            <div style={{
                background: 'linear-gradient(to left, #055878, #13769a)', /* 从左到右的渐变色 */
                width: '100%',
                height: 'calc(100vh) ',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <img src={"https://gsrd-web-images.tos-cn-beijing.volces.com/door.png"} style={{ position: 'fixed', bottom: '0', width: '800px' }} />
                <Row style={{ height: "15vh" }}></Row>
                <Row style={{
                    height: "15vh"
                    , display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <img src={"https://gsrd-web-images.tos-cn-beijing.volces.com/gsrdlogo.png"}
                        style={{ width: '420px', margin: '5px 0px' }} />
                    <span style={{
                        color: 'white',
                        fontSize: '46px',
                        fontWeight: 700,
                        padding: '0px 20px 20px 0px',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        Genome Sequencing of Rare Disease – 100K<sup style={{ fontSize: '18px' }}>West China Hospital</sup>
                    </span>
                </Row>
                <Row style={{
                    height: '35vh',
                    width: '50%',
                    margin: '12px',
                    // width: '55%',
                    marginTop: '50px',
                    display: 'flex', justifyContent: 'space-between'
                }}>
                    {styleContent("https://gsrd-web-images.tos-cn-beijing.volces.com/home01.png", 321365014, "SNVs&nbsp;&&nbsp;Indels")}
                    {styleContent("https://gsrd-web-images.tos-cn-beijing.volces.com/home02.png", 41998, "Samples", '75px')}
                    {styleContent("https://gsrd-web-images.tos-cn-beijing.volces.com/home03.png", 954, 'Phenotype<br/>HPO term', '75px')}

                </Row>
                <Row style={{
                    height: 'calc(10vh)',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'space-between',
                    width: '60%',
                    marginBottom: '20px'
                }}>
                    <ConfigProvider
                        theme={{
                            components: {
                                Button: {
                                    colorPrimary: '#008CBA',
                                    lineWidth: 0,
                                },
                            },
                        }}
                    >
                        <Button
                            type="primary"
                            size="large"
                            style={{
                                width: '300px',
                                height: '60px',
                                borderRadius: '10px',
                                fontSize: '24px',
                            }}
                            onClick={() => {
                                setKey('home');
                                navigate('/home', { state: { pages: "upload" } })
                            }
                            }
                        >
                            <CaretRightOutlined /> Variants Search
                        </Button>
                    </ConfigProvider>
                    <ConfigProvider
                        theme={{
                            components: {
                                Button: {
                                    colorPrimary: '#008CBA',
                                    lineWidth: 0,
                                },
                            },
                        }}
                    >
                        <Button
                            type="primary"
                            size="large"
                            style={{
                                width: '300px',
                                height: '60px',
                                borderRadius: '10px',
                                fontSize: '24px',

                            }}
                            onClick={() => {
                                setKey('home');
                                navigate('/home')
                            }}
                        >
                            <CaretRightOutlined /> Phasing & Imputation
                        </Button>
                    </ConfigProvider>
                    <ConfigProvider
                        theme={{
                            components: {
                                Button: {
                                    colorPrimary: '#008CBA',
                                    lineWidth: 0,
                                },
                            },
                        }}
                    >
                        <Button
                            type="primary"
                            size="large"
                            style={{
                                width: '300px',
                                height: '60px',
                                borderRadius: '10px',
                                fontSize: '24px',

                            }}
                            onClick={() => {
                                setKey('searchInput');
                                navigate("/searchInput")
                            }
                            }
                        >
                            <CaretRightOutlined /> Job History
                        </Button>
                    </ConfigProvider>
                </Row>
                <Row style={{
                    height: '25vh',
                    backgroundColor: 'white',
                    width: '100%',
                    display: "flex",
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '30px 0px 10vh 0px'

                }}
                >
                    <span style={{
                        fontSize: '18px',
                        fontWeight: '500',
                        margin: '0px 30vh',
                        lineHeight: '40px'
                    }}>
                        The GSRD-100K<sup style={{ fontSize: '12px' }}>WCH</sup> project aims to collect and analyze phenotype information and whole genome sequencing data from 100,000 rare disease patients and 20,000 healthy individuals.
                        This project seeks to uncover the molecular causes and mechanisms of rare diseases, advancing precision diagnosis and innovative treatment techniques.
                        This extensive effort focuses on improving early diagnosis, which is crucial for disease intervention and birth defect prevention.
                    </span>
                </Row>
            </div>
        </div>
    )
}
