import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ConfigProvider
    theme={{
      token:
      {
        colorPrimary: 'rgb(44, 120, 150)',
        borderRadius: 3,
        colorTextDescription: 'rgb(44, 120, 150)',
        colorTextHeading: 'rgb(44, 120, 150)',
      }
    }}
  >
    <HashRouter> 
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </HashRouter>
  </ConfigProvider>
);
