import './index.css';
import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, Space, Modal } from 'antd';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import API_ROUTES from '../../api'

export default function RegistFirstStep() {

    const navigate = useNavigate();
    const [countdown, setCountdown] = useState(
        localStorage.getItem('countdown') ? JSON.parse(localStorage.getItem('countdown')) : 0
    );
    const [isDisabled, setIsDisabled] = useState(false);

    const handleButtonClick = () => {
        const inputEmail = form.getFieldValue('email')
        if (null === inputEmail || undefined === inputEmail) {
            return (
                Modal.error({
                    content: 'The entered email address cannot be empty!'
                })
            )
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!inputEmail || !emailRegex.test(inputEmail)) {
            return (
                Modal.error({
                    content: 'Please enter a valid email address.'
                })
            );
        }
        axios
            .post(API_ROUTES.api_auth, {
                email: inputEmail
            })
            .then(function (response) {
                Modal.success({
                    content: 'Email send success!'
                });
            })
            .catch(function (error) {
                Modal.error({
                    content: 'Email send fail!'
                });
                setIsDisabled(false);
                localStorage.removeItem('countdown');
            });
        setCountdown(5);
        setIsDisabled(true);
    };

    useEffect(() => {
        let timer;

        if (countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prevCountdown) => {
                    localStorage.setItem('countdown', JSON.stringify(prevCountdown - 1));
                    return prevCountdown - 1;
                });
            }, 1000);
        } else {
            setIsDisabled(false);
            localStorage.removeItem('countdown');
        }

        return () => {
            clearInterval(timer);
        };
    }, [countdown]);


    const onFinish = (values) => {
        axios.post(API_ROUTES.api_regist, {
            email: values.email,
            password: values.password,
            realName: values.realName,
            org: values.org,
            secretKey: values.secretKey
        })
            .then(function (response) {
                if (response.data.code === 500) {
                    Modal.error({
                        content: response.data.msg
                    });
                } else {
                    Modal.success({
                        content: 'Login success!'
                    });
                    navigate('/login')
                }
            })
            .catch(function (error) {
                Modal.error({
                    content: error
                });
            });
    };

    const testChange = () => {
        console.log(form.getFieldValue('email'))
    }

    const [form] = Form.useForm();
    return (
        <div style={{ marginTop: '140px' }}>
            <span style={{
                fontSize: '60px',
                fontWeight: '700',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '60px'
            }}>
                Register
            </span>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Form
                    id="regist_form"
                    name="login"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onChange={testChange}
                    style={{
                        width: '38.2%',
                    }}
                    form={form}
                >
                    <Row>
                        <Col span={18}>
                            <Form.Item
                                label={(
                                    <div style={{ fontSize: '24px', fontWeight: '600' }}>
                                        Email
                                    </div>
                                )}
                                name="email"
                                rules={[{
                                    required: true,
                                    message: 'Please input your Email address!'
                                }, {
                                    type: 'email',
                                    message: 'The input is not valid Email!',
                                }
                                ]}
                            >
                                <Input
                                    placeholder='please input your EmailAdress'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={5} offset={1}>
                            <Button
                                type="primary"
                                style={{ width: '100%' }}
                                onClick={handleButtonClick}
                                disabled={isDisabled}>
                                {isDisabled ? `Sending in ${countdown}s` : 'Get secretKey'}
                            </Button>
                        </Col>
                    </Row>

                    <Form.Item
                        label={(
                            <div style={{ fontSize: '24px', fontWeight: '600' }}>
                                RealName
                            </div>
                        )}
                        name="realName"
                        rules={[{
                            required: true,
                            message: 'Please input your RealName!'
                        }]}
                    >
                        <Input placeholder='Please input your RealName!' />
                    </Form.Item>
                    <Form.Item
                        label={(
                            <div style={{ fontSize: '24px', fontWeight: '600' }}>
                                Organization
                            </div>
                        )}
                        name="org"
                        rules={[{
                            required: true,
                            message: 'Please input your organization!'
                        }]}
                    >
                        <Input placeholder='Please input your organization!' />
                    </Form.Item>
                    <Form.Item
                        label={(
                            <div style={{ fontSize: '24px', fontWeight: '600' }}>
                                Password
                            </div>
                        )}
                        name="password"
                        rules={[{
                            required: true,
                            message: 'Please input your password!'
                        },
                        {
                            min: 8,
                            message: 'Password must be at least 8 characters long!'
                        },
                        {
                            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
                            message: 'Password must contain at least one uppercase letter, one lowercase letter, and one digit!'
                        }
                        ]}
                    >
                        <Input.Password placeholder='Please input your password!' />
                    </Form.Item>
                    <Form.Item
                        label={(
                            <div style={{ fontSize: '24px', fontWeight: '600' }}>
                                Confirm Password
                            </div>
                        )}
                        name="re-password"
                        rules={[{
                            required: true,
                            message: 'Please input your password!'
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('The two passwords do not match!');
                            },
                        })
                        ]}
                    >
                        <Input.Password placeholder='Please input your password again!' />
                    </Form.Item>

                    <Form.Item
                        label={(
                            <div style={{ fontSize: '24px', fontWeight: '600' }}>
                                SecreteKey
                            </div>
                        )}
                        name="secretKey"
                        rules={[{
                            required: true,
                            message: 'Please input secretKey!'
                        }
                        ]}
                    >
                        <Input
                            placeholder='please input your secretKey'
                        />
                    </Form.Item>

                    <Form.Item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button
                            type="primary"
                            size="large"
                            htmlType="submit"
                            style={{
                                width: '200px',
                                height: '60px',
                                color: '#FFFFFF',
                                borderRadius: '40px',
                                fontWeight: '800',
                                fontSize: '26px',
                                marginTop: '30px'
                            }}
                        >
                            Sumbit
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}
