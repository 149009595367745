
import './App.css'
import Login from './pages/Login';
import ResetPwd from './pages/ResetPwd';
import Home from './pages/Home';
import RegistFirstStep from './pages/RegistFirstStep';
import Manage from './pages/Manage';
import ManageLogin from './pages/ManageLogin';
import ForgetPwd from './pages/ForgetPwd';
import Display from './pages/Display';
import AboutUs from './pages/AboutUs';
import Search from './pages/Search';
import SearchInput from './pages/Search/SearchInput';
import { Col, ConfigProvider, Dropdown, Row, Menu, Select, Input } from 'antd';
import { Route, Navigate, Routes, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { DownOutlined, RetweetOutlined, LoginOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons'
import ScrollToTop from './pages/ScrollTop/ScrollToTop';

function App() {

  const userInfo = localStorage.getItem("userInfo");

  const navigate = useNavigate()

  const [currentKey, setCurrentKey] = useState('display');

  useEffect(() => {
    console.log(currentKey)
  }

    , [currentKey])

  const menuItems = [
    {
      label: (<span style={{ fontSize: '18px' }}>HOME</span>),
      key: 'display',
    },
    {
      label: (<span style={{ fontSize: '18px' }}>IMPUTATION</span>),
      key: 'home',
    },
    {
      label: (<span style={{ fontSize: '18px' }}>SEARCH</span>),
      key: 'searchInput',
    },
    {
      label: (<span style={{ fontSize: '18px' }}>ABOUT US</span>),
      key: 'aboutUs',
    }
  ]

  const items = [
    {
      key: '1',
      label: (
        <a onClick={() => { navigate('/resetPwd') }}>
          Change password
        </a>
      ),
      icon: <RetweetOutlined />,

    },
    {
      key: '2',
      label: (
        <a onClick={() => {
          navigate('/display')
          localStorage.clear()
        }}>
          Log out
        </a>
      ),
      icon: <LoginOutlined />,

    },
  ];

  function isValidFormat(input) {
    const regex = /^chr[0-9a-zA-Z]+:[0-9]+-[A-Za-z]-[A-Za-z]$/;
    return regex.test(input);
  }

  return (
    <div >
      <ScrollToTop />
      <div
        style={{
          position: 'fixed',
          top: 0,
          width: '100%',
          minHeight: '60px',
          backgroundColor: 'white',
          background: 'linear-gradient(to left, #055878, #13769a)',
          zIndex: '1'
        }}
      >
        <Row style={{ zIndex: '201' }}>
          <Col span={10} offset={2} style={{
            display: 'flex',
            alignItems: 'center',
            height: '60px',
          }}>
            <a style={{
              fontSize: '32px',
              fontWeight: '700',
              color: 'white',
            }}
              onClick={() => navigate('/display')}
            >
              GSRD-100K
              <sup style={{ fontSize: '16px' }}>WCH</sup>
            </a>
          </Col>
          <Col span={7} style={{
            display: 'flex',
            alignItems: 'center',
            height: '60px',
          }}>
            <ConfigProvider theme={{
              components: {
                Menu: {
                  horizontalItemSelectedColor: 'white',
                  itemColor: 'white',
                  itemHoverColor: 'white'
                }
              }
            }}>
              <Menu
                mode="horizontal"
                onClick={(e) => {
                  setCurrentKey(e.key)
                  navigate(e.key)
                }}
                selectedKeys={[currentKey]}
                items={menuItems}
                style={{
                  width: '100%',
                  minWidth: 0,
                  minHeight: '50px',
                  paddingTop: '6px',
                  backgroundColor: 'rgba(255, 255, 255, 0)'
                }}
              />
            </ConfigProvider>
          </Col>

          <Col span={5} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {userInfo ?
              <Dropdown
                menu={{
                  items,
                }}
              >
                <a onClick={(e) => e.preventDefault()} style={{ color: 'white', fontSize: '18px' }}>
                  {localStorage.getItem('email')} &nbsp;
                  <DownOutlined />
                </a>
              </Dropdown>
              :
              <span onClick={() => navigate('/login')} style={{ color: 'white', fontSize: '18px' }}>Login</span>

            }
          </Col>
          {/* <Col span={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Select
              defaultValue="1"
              style={{
                width: 100,
              }}
              size='small'
              onChange={(value) => { console.log(value) }}
              options={[
                {
                  value: '1',
                  label: 'English',
                },
                {
                  value: '2',
                  label: '中文',
                },
              ]}
            />
          </Col> */}
        </Row>

      </div>

      <div style={{
        overflowY: 'auto',
        minHeight: 'calc(100vh - 132px)',
      }}>
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path='/login' element={<Login />} />
          <Route path='/resetPwd' element={<ResetPwd />} />
          <Route path='/registFirstStep' element={<RegistFirstStep />} />
          <Route path='/manage' element={<Manage />} />
          <Route path='/manageLogin' element={<ManageLogin />} />
          <Route path='/forgetPwd' element={<ForgetPwd />} />
          <Route path='/display' element={<Display setKey={setCurrentKey} />} />
          <Route path='/aboutUs' element={<AboutUs />} />
          <Route path='/search' element={<Search />} />
          <Route path='/searchInput' element={<SearchInput />} />
          <Route path="*" element={<Navigate to="/display" />} />
        </Routes>
      </div>


      <div
        style={{
          textAlign: 'center',
          position: 'fixed',
          width: '100%',
          bottom: 0,
          minHeight: '60px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '14px',
          backgroundColor: 'white',
        }}
      >
        Web Development @2022 Created by Institute of Rare Diseases, West China Hospital, Sichuan University &nbsp;
        <a href='https://beian.miit.gov.cn/'>
          蜀ICP备2023012330号-2
        </a>
        &nbsp;
        <img src={'https://gsrd-web-images.tos-cn-beijing.volces.com/beian.png'} height='12px' />
        &nbsp;
        <a href="https://beian.mps.gov.cn/#/query/webSearch?code=51015602000840"
          rel="noreferrer"
          target="_blank">
          川公网安备51015602000840
        </a>
      </div>
    </div>
  );
}

export default App;

