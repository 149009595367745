import {
    notification, Button, Row, Col, Statistic, Card, Tooltip,
} from 'antd'
import React, { useEffect, useState } from 'react'
import './index.css'
import SearchOne from './SearchOne';
import SearchTwo from './SearchTwo';
export default function Search({ dataInfo }) {
    const copyText = (placement) => {
        var textToCopy = document.querySelector('.content').innerText;
        navigator.clipboard.writeText(textToCopy)
            .then(function () {
                openNotification(placement)
            }, function (err) {

            });
    }

    const [api, contextHolder] = notification.useNotification();
    const openNotification = (placement) => {
        api['success']({
            message: `Copy success!`,
            placement
        });
    };

    const titleInfo = (value) => {
        return (<span style={{ fontSize: '18px' }}>
            {value}
        </span>)
    }


    return (
        <div style={{
            margin: '70px 30px',
        }}>
            {contextHolder}

            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#13769a', padding: '10px', color: 'white' }}>
                    <img src='https://gsrd-web-images.tos-cn-beijing.volces.com/gene1.png'
                        style={{ height: '30px', width: '30px' }}
                    />
                    <span style={{ margin: '0px 15px 0px 8px ', fontSize: '28px', fontWeight: '700' }} className='content'>
                        {"Chr" +
                            dataInfo?.searchInfo.searchChr
                            + ':' +
                            dataInfo?.searchInfo.searchPos +
                            '-' +
                            dataInfo?.searchInfo.searchRef +
                            '-' +
                            dataInfo?.searchInfo.searchAlt
                        }
                    </span>
                </div>

                <Button onClick={() => copyText('top')}
                    shape='round'
                    style={{
                        marginLeft: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }} >
                    Copy variant ID
                </Button>
            </div>
            <Card style={{ backgroundColor: 'aliceblue', borderLeft: '8px solid #13769a', borderTop: 'none', borderRadius: '0' }} >
                <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '0px 50px' }}>
                    <div className='colName' span={3} >
                        <Tooltip placement="top" title='Quality control filters that this variant failed (if any)'>
                            <Statistic title={titleInfo("Filters")} value={"Pass"} />
                        </Tooltip>
                    </div>
                    <div className='colName' span={3}>
                        <Tooltip placement="top" title='Alternate allele count in high quality genotypes'>
                            <Statistic title={titleInfo("Allele Count")} value={dataInfo?.AC} />
                        </Tooltip>
                    </div>
                    <div className='colName' span={3}>
                        <Tooltip placement="top" title='Total number of called high quality genotypes'>
                            <Statistic title={titleInfo("Allele Number")} value={dataInfo?.AN} />
                        </Tooltip>
                    </div>
                    <div className='colName' span={3}>
                        <Tooltip placement="top" title='Alternate allele frequency in high quality genotypes'>
                            <Statistic title={titleInfo("Allele Frequency")} value={dataInfo?.AF} />
                        </Tooltip>
                    </div>
                    <div className='colName' span={10}>
                        <Tooltip placement="top" title="Fraction of individuals with > 30x coverage at this variant's locus">
                            <Statistic title={titleInfo("Fraction of individuals with > 30x coverage")} value={1} />
                        </Tooltip>
                    </div>
                </Row>
            </Card>
            <SearchTwo resultInfo={dataInfo} />
            <SearchOne resultInfo={dataInfo} />

        </div >
    )
}
