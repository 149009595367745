import './index.css'
import React, { useState } from 'react';
import { Form, Input, Button, Typography, Space, Modal, Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom'
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import axios from 'axios';
import API_ROUTES from '../../api'

export default function Login() {

    const navigate = useNavigate();
    const { Text, Link } = Typography;

    const [form] = Form.useForm();

    const onFinish = (values) => {
        axios
            .post(API_ROUTES.api_login, {
                userName: values.email,
                password: values.password,
            })
            .then(function (response) {
                if (response.data.code === 500) {
                    Modal.error({
                        content: response.data.msg
                    });
                }
                if (response.data.code === 200) {
                    Modal.success({
                        content: 'Login success!'
                    });
                    //将返回结果保存到local storage
                    localStorage.setItem('userInfo', response.data.data.userInfo)
                    localStorage.setItem("email", response.data.data.email)
                    localStorage.setItem("org", response.data.data.org)
                    localStorage.setItem('realName', response.data.data.realName)
                    localStorage.setItem('uploadFlag', response.data.data.uploadFlag)
                    localStorage.setItem('idCard', response.data.data.idCard)
                    localStorage.setItem('studentCertificate', response.data.data.studentCertificate)
                    localStorage.setItem('idCardBack', response.data.data.idCardBack)
                    navigate('/home')
                }
            })
            .catch(function (error) {
                Modal.error({
                    content: error
                });
            });
    };

    return (
        <div style={{ marginTop: '200px' }}>
            <span style={{
                fontSize: '40px',
                fontWeight: '700',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '60px'
            }}>
                Please log in to access more features.
            </span>

            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Form
                    name="login"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    form={form}
                    style={{ width: '38.2%' }}
                >
                    <Form.Item
                        label={(
                            <div style={{ fontSize: '24px', fontWeight: '600' }}>
                                Email
                            </div>
                        )}
                        name="email"
                        rules={[{
                            required: true,
                            message: 'Please input your Email adress!'
                        }]}
                    >
                        <Input
                            placeholder='Please enter your email account'
                            prefix={<UserOutlined className="site-form-item-icon" />
                            } />
                    </Form.Item>
                    <Form.Item
                        label={(
                            <div style={{ fontSize: '24px', fontWeight: '600' }}>
                                Password
                            </div>
                        )}
                        name="password"
                        rules={[{
                            required: true,
                            message: 'Please input your password!'
                        }]}
                    >
                        <Input.Password
                            placeholder='Please enter your password'
                        />
                    </Form.Item>

                    <Form.Item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button
                            type="primary"
                            size="large"
                            htmlType="submit"
                            style={{
                                width: '200px',
                                height: '60px',
                                color: '#FFFFFF',
                                borderRadius: '40px',
                                fontWeight: '800',
                                fontSize: '26px',
                                marginTop: '30px'
                            }}
                        >
                            Login
                        </Button>
                    </Form.Item>
                </Form>
            </div>


            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Link
                    target="_blank"
                    onClick={() => { navigate('/registFirstStep') }}
                    style={{ fontSize: '20px', color: 'rgb(44, 120, 150)', fontWeight: '500', margin: '10px' }}
                >
                    New User ? Sign up for free!
                </Link>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Link
                    target="_blank"
                    onClick={() => { navigate('/forgetPwd') }}
                    style={{ fontSize: '20px', color: 'rgb(44, 120, 150)', fontWeight: '500', margin: '10px' }}
                >
                    Forgotten your password? Reset your password!
                </Link>
            </div>
        </div>
    )
}
