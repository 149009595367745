import { useEffect, useRef } from "react"
import * as echarts from "echarts";
import china from './china.json'

const Flights = () => {

    const chartRef = useRef(null)

    useEffect(() => {

        let chartInstance = null;

        const data =
            [
                // { name: '广东省广州市', value: 689 },
                { name: '北京市', value: 497 },
                { name: '新疆维吾尔自治区', value: 490 },
                { name: '重庆市', value: 482 },
                { name: '甘肃省', value: 473 },
                { name: '广西壮族自治区柳州市', value: 357 },
                { name: '广西壮族自治区南宁市', value: 290 },
                { name: '广西壮族自治区', value: 284 },
                { name: '贵州省黔东南苗族侗族自治州', value: 284 },
                { name: '宁夏回族自治区', value: 203 },
                { name: '四川省南充市', value: 190 },
                { name: '四川省乐山市', value: 182 },
                { name: '贵州省贵阳市', value: 176 },
                { name: '云南省昭通市', value: 173 },
                { name: '贵州省毕节市', value: 164 },
                { name: '贵州省铜仁市', value: 160 },
                { name: '山东省济宁市', value: 159 },
                { name: '四川省自贡市', value: 158 },
                { name: '广西壮族自治区玉林市', value: 149 },
                { name: '青海省', value: 144 },
                { name: '四川省宜宾市', value: 140 },
                { name: '江西省南昌市', value: 138 },
                { name: '陕西省', value: 134 },
                { name: '江苏省徐州市', value: 131 },
                { name: '湖南省长沙市', value: 131 },
                { name: '河北省石家庄市', value: 127 },
                { name: '广西壮族自治区梧州市', value: 123 },
                { name: '江苏省宿迁市', value: 116 },
                { name: '河南省郑州市', value: 114 },
                { name: '西藏自治区拉萨市', value: 113 },
                { name: '甘肃省兰州市', value: 108 },
                { name: '上海市', value: 105 },
                { name: '重庆市巫山县', value: 104 },
                { name: '西藏自治区日喀则市', value: 102 },
                { name: '内蒙古自治区通辽市', value: 102 },
                { name: '河南省洛阳市', value: 101 },
                { name: '四川省达州市', value: 99 },
                { name: '江西省赣州市', value: 99 },
                { name: '四川省巴中市', value: 97 },
                { name: '四川省德阳市', value: 97 },
                { name: '湖北省宜昌市', value: 97 },
                { name: '四川省绵阳市', value: 96 },
                { name: '广西壮族自治区钦州市', value: 96 },
                { name: '湖南省', value: 93 },
                { name: '云南省保山市', value: 87 },
                { name: '河北省', value: 82 },
                { name: '福建省龙岩市', value: 82 },
                { name: '辽宁省沈阳市', value: 79 },
                { name: '广西壮族自治区贵港市', value: 78 },
                { name: '安徽省阜阳市', value: 74 },
                { name: '甘肃省临夏回族自治州', value: 74 },
                { name: '贵州省遵义市', value: 72 },
                { name: '河南省', value: 71 },
                { name: '天津市', value: 71 },
                { name: '重庆市荣昌区', value: 69 },
                { name: '贵州省黔南布依族苗族自治州', value: 68 },
                { name: '安徽省', value: 65 },
                { name: '河北省邯郸市', value: 65 },
                { name: '河南省南阳市', value: 65 },
                { name: '浙江省', value: 64 },
                { name: '四川省成都市', value: 8282 },
                { name: '湖北省黄石市', value: 60 },
                { name: '四川省内江市', value: 60 },
                { name: '吉林省长春市', value: 58 },
                { name: '江苏省连云港市', value: 57 },
                { name: '黑龙江省哈尔滨市', value: 56 },
                { name: '四川省凉山彝族自治州', value: 56 },
                { name: '河北省邢台市', value: 55 },
                { name: '四川省广安市', value: 54 },
                { name: '湖北省荆州市', value: 53 },
                { name: '广西壮族自治区桂林市', value: 53 },
                { name: '内蒙古自治区鄂尔多斯市', value: 53 },
                { name: '江西省', value: 52 },
                { name: '湖北省荆门市', value: 50 },
                { name: '吉林省四平市', value: 49 },
                { name: '河北省保定市', value: 49 },
                { name: '四川省遂宁市', value: 49 },
                { name: '云南省', value: 48 },
                { name: '浙江省杭州市', value: 48 },
                { name: '四川省广元市', value: 47 },
                { name: '安徽省宿州市', value: 46 },
                { name: '四川省眉山市', value: 46 },
                { name: '湖北省武汉市', value: 45 },
                { name: '山东省', value: 45 },
                { name: '贵州省', value: 44 },
                { name: '福建省福州市', value: 44 },
                { name: '广西壮族自治区贺州市', value: 43 },
                { name: '重庆市渝北区', value: 42 },
                { name: '四川省雅安市', value: 40 },
                { name: '广西壮族自治区来宾市', value: 40 },
                { name: '吉林省', value: 39 },
                { name: '吉林省辽源市', value: 39 },
                { name: '山西省太原市', value: 38 },
                { name: '重庆市长寿区', value: 37 },
                { name: '四川省资阳市', value: 37 },
                { name: '重庆市奉节县', value: 35 },
                { name: '广西壮族自治区百色市', value: 35 },
                { name: '西藏自治区山南市', value: 34 },
                { name: '黑龙江省绥化市', value: 34 },
                { name: '安徽省淮北市', value: 32 },
                { name: '黑龙江省齐齐哈尔市', value: 32 },
                { name: '云南省曲靖市', value: 32 },
                { name: '黑龙江省', value: 31 },
                { name: '江西省宜春市', value: 31 },
                { name: '广西壮族自治区河池市', value: 31 },
                { name: '四川省泸州市', value: 30 },
                { name: '山西省', value: 30 },
                { name: '重庆市巴南区', value: 29 },
                { name: '西藏自治区昌都市', value: 29 },
                { name: '湖北省恩施土家族苗族自治州', value: 28 },
                { name: '江西省抚州市', value: 28 },
                { name: '内蒙古自治区呼和浩特市', value: 28 },
                { name: '湖北省', value: 27 },
                { name: '内蒙古自治区乌兰察布市', value: 27 },
                { name: '河南省商丘市', value: 27 },
                { name: '重庆市开州区', value: 27 },
                { name: '重庆市垫江县', value: 27 },
                { name: '重庆市合川区', value: 27 },
                { name: '四川省甘孜藏族自治州', value: 27 },
                { name: '浙江省宁波市', value: 27 },
                { name: '广西壮族自治区崇左市', value: 27 },
                { name: '云南省楚雄彝族自治州', value: 27 },
                { name: '内蒙古自治区', value: 26 },
                { name: '内蒙古自治区包头市', value: 26 },
                { name: '河南省信阳市', value: 25 },
                { name: '西藏自治区那曲市', value: 25 },
                { name: '江苏省', value: 25 },
                { name: '广西壮族自治区北海市', value: 25 },
                { name: '云南省红河哈尼族彝族自治州', value: 25 },
                { name: '山西省吕梁市', value: 25 },
                { name: '重庆市沙坪坝区', value: 24 },
                { name: '辽宁省', value: 23 },
                { name: '重庆市潼南区', value: 23 },
                { name: '湖南省衡阳市', value: 22 },
                { name: '湖北省黄冈市', value: 22 },
                { name: '重庆市万州区', value: 22 },
                { name: '新疆维吾尔自治区喀什地区', value: 22 },
                { name: '河南省周口市', value: 21 },
                { name: '广东省佛山市', value: 21 },
                { name: '湖南省岳阳市', value: 21 },
                { name: '江西省吉安市', value: 20 },
                { name: '重庆市丰都县', value: 20 },
                { name: '湖南省怀化市', value: 20 },
                { name: '云南省普洱市', value: 20 },
                { name: '湖南省邵阳市', value: 19 },
                { name: '湖北省襄阳市', value: 19 },
                { name: '重庆市云阳县', value: 19 },
                { name: '重庆市永川区', value: 19 },
                { name: '重庆市江津区', value: 18 },
                { name: '重庆市忠县', value: 18 },
                { name: '江西省上饶市', value: 18 },
                { name: '湖南省湘潭市', value: 18 },
                { name: '福建省', value: 17 },
                { name: '浙江省温州市', value: 17 },
                { name: '重庆市石柱土家族自治县', value: 17 },
                { name: '广东省肇庆市', value: 17 },
                { name: '云南省大理白族自治州', value: 17 },
                { name: '湖南省娄底市', value: 16 },
                { name: '河北省衡水市', value: 16 },
                { name: '河南省许昌市', value: 16 },
                { name: '福建省宁德市', value: 16 },
                { name: '浙江省衢州市', value: 16 },
                { name: '重庆市南岸区', value: 16 },
                { name: '广东省汕尾市', value: 16 },
                { name: '云南省昆明市', value: 16 },
                { name: '江西省九江市', value: 15 },
                { name: '福建省莆田市', value: 15 },
                { name: '河南省平顶山市', value: 15 },
                { name: '山西省晋中市', value: 15 },
                { name: '河南省安阳市', value: 15 },
                { name: '重庆市酉阳土家族苗族自治县', value: 15 },
                // { name: '广东省', value: 15 },
                       { name: '广东省', value: 704 },
                { name: '西藏自治区林芝市', value: 15 },
                { name: '山东省烟台市', value: 14 },
                { name: '山西省长治市', value: 14 },
                { name: '重庆市梁平区', value: 14 },
                { name: '重庆市涪陵区', value: 14 },
                { name: '贵州省六盘水市', value: 14 },
                { name: '辽宁省朝阳市', value: 14 },
                { name: '辽宁省鞍山市', value: 14 },
                { name: '河北省沧州市', value: 13 },
                { name: '辽宁省锦州市', value: 13 },
                { name: '重庆市黔江区', value: 13 },
                { name: '重庆市北碚区', value: 13 },
                { name: '重庆市巫溪县', value: 13 },
                { name: '广东省揭阳市', value: 13 },
                { name: '广东省汕头市', value: 13 },
                { name: '贵州省黔西南布依族苗族自治州', value: 13 },
                { name: '河南省开封市', value: 13 },
                { name: '河北省唐山市', value: 12 },
                { name: '湖南省永州市', value: 12 },
                { name: '山西省晋城市', value: 12 },
                { name: '河南省驻马店市', value: 12 },
                { name: '山东省聊城市', value: 12 },
                { name: '重庆市璧山区', value: 12 },
                { name: '重庆市九龙坡区', value: 12 },
                { name: '重庆市大足区', value: 12 },
                { name: '重庆市綦江区', value: 12 },
                { name: '四川省阿坝藏族羌族自治州', value: 12 },
                { name: '河南省焦作市', value: 12 },
                { name: '湖南省郴州市', value: 12 },
                { name: '云南省德宏傣族景颇族自治州', value: 12 },
                { name: '辽宁省葫芦岛市', value: 11 },
                { name: '河北省承德市', value: 11 },
                { name: '陕西省榆林市', value: 11 },
                { name: '湖南省常德市', value: 11 },
                { name: '湖南省株洲市', value: 11 },
                { name: '云南省文山壮族苗族自治州', value: 11 },
                { name: '河南省新乡市', value: 11 },
                { name: '山西省忻州市', value: 11 },
                { name: '湖南省益阳市', value: 10 },
                { name: '广东省茂名市', value: 10 },
                { name: '辽宁省大连市', value: 10 },
                { name: '内蒙古自治区赤峰市', value: 10 },
                { name: '重庆市江北区', value: 10 },
                { name: '西藏自治区', value: 10 },
                { name: '广东省深圳市', value: 10 },
                { name: '广东省云浮市', value: 10 },
                { name: '广东省江门市', value: 10 },
                { name: '贵州省安顺市', value: 10 },
                { name: '广西壮族自治区防城港市', value: 10 },
                { name: '山西省运城市', value: 10 },
                { name: '山东省菏泽市', value: 9 },
                { name: '辽宁省营口市', value: 9 },
                { name: '山西省大同市', value: 9 },
                { name: '重庆市南川区', value: 9 },
                { name: '河南省三门峡市', value: 9 },
                { name: '广东省清远市', value: 9 },
                { name: '云南省玉溪市', value: 9 },
                { name: '湖北省孝感市', value: 8 },
                { name: '山西省临汾市', value: 8 },
                { name: '陕西省西安市', value: 8 },
                { name: '内蒙古自治区呼伦贝尔市', value: 8 },
                { name: '河北省张家口市', value: 8 },
                { name: '重庆市秀山土家族苗族自治县', value: 8 },
                { name: '重庆市铜梁区', value: 8 },
                { name: '浙江省台州市', value: 8 },
                { name: '江西省萍乡市', value: 8 },
                { name: '甘肃省张掖市', value: 8 },
                { name: '山西省朔州市', value: 7 },
                { name: '辽宁省丹东市', value: 7 },
                { name: '重庆市渝中区', value: 7 },
                { name: '广东省河源市', value: 7 },
                { name: '西藏自治区阿里地区', value: 7 },
                { name: '陕西省渭南市', value: 7 },
                { name: '湖北省随州市', value: 7 },
                { name: '辽宁省铁岭市', value: 7 },
                { name: '辽宁省抚顺市', value: 7 },
                { name: '浙江省金华市', value: 7 },
                { name: '吉林省吉林市', value: 6 },
                { name: '山东省枣庄市', value: 6 },
                { name: '辽宁省本溪市', value: 6 },
                { name: '内蒙古自治区巴彦淖尔市', value: 6 },
                { name: '江西省鹰潭市', value: 6 },
                { name: '广东省潮州市', value: 6 },
                { name: '广东省惠州市', value: 6 },
                { name: '广东省东莞市', value: 6 },
                { name: '云南省临沧市', value: 6 },
                { name: '河南省漯河市', value: 6 },
                { name: '黑龙江省牡丹江市', value: 5 },
                { name: '重庆市城口县', value: 5 },
                { name: '黑龙江省佳木斯市', value: 5 },
                { name: '河南省濮阳市', value: 5 },
                { name: '黑龙江省鸡西市', value: 5 },
                { name: '安徽省安庆市', value: 5 },
                { name: '广东省梅州市', value: 5 },
                { name: '广东省湛江市', value: 5 },
                { name: '陕西省安康市', value: 5 },
                { name: '四川省攀枝花市', value: 5 },
                { name: '湖北省鄂州市', value: 5 },
                { name: '吉林省通化市', value: 5 },
                { name: '吉林省白城市', value: 5 },
                { name: '辽宁省辽阳市', value: 5 },
                { name: '云南省丽江市', value: 5 },
                { name: '重庆市武隆区', value: 5 },
                { name: '辽宁省阜新市', value: 4 },
                { name: '黑龙江省大兴安岭地区', value: 4 },
                { name: '辽宁省盘锦市', value: 4 },
                { name: '河北省秦皇岛市', value: 4 },
                { name: '重庆市彭水苗族土家族自治县', value: 4 },
                { name: '海南省海口市', value: 4 },
                { name: '福建省三明市', value: 4 },
                { name: '广东省中山市', value: 4 },
                { name: '甘肃省天水市', value: 4 },
                { name: '青海省玉树藏族自治州', value: 4 },
                { name: '山东省淄博市', value: 4 },
                { name: '山东省泰安市', value: 4 },
                { name: '黑龙江省双鸭山市', value: 4 },
                { name: '湖北省咸宁市', value: 4 },
                { name: '湖南省湘西土家族苗族自治州', value: 4 },
                { name: '内蒙古自治区兴安盟', value: 4 },
                { name: '浙江省湖州市', value: 4 },
                { name: '北京市通州区', value: 3 },
                { name: '河北省廊坊市', value: 3 },
                { name: '黑龙江省伊春市', value: 3 },
                { name: '山东省德州市', value: 3 },
                { name: '陕西省宝鸡市', value: 3 },
                { name: '山东省济南市', value: 3 },
                { name: '福建省南平市', value: 3 },
                { name: '江西省景德镇市', value: 3 },
                { name: '广东省韶关市', value: 3 },
                { name: '甘肃省陇南市', value: 3 },
                { name: '安徽省池州市', value: 3 },
                { name: '安徽省合肥市', value: 3 },
                { name: '青海省海西蒙古族藏族自治州', value: 3 },
                { name: '黑龙江省大庆市', value: 3 },
                { name: '云南省西双版纳傣族自治州', value: 3 },
                { name: '云南省怒江傈僳族自治州', value: 3 },
                { name: '江苏省无锡市', value: 3 },
                { name: '浙江省丽水市', value: 3 },
                { name: '安徽省蚌埠市', value: 2 },
                { name: '甘肃省酒泉市', value: 2 },
                { name: '北京市房山区', value: 2 },
                { name: '江苏省淮安市', value: 2 },
                { name: '江苏省盐城市', value: 2 },
                { name: '山东省滨州市', value: 2 },
                { name: '山东省青岛市', value: 2 },
                { name: '山东省日照市', value: 2 },
                { name: '江苏省南通市', value: 2 },
                { name: '重庆市大渡口区', value: 2 },
                { name: '山东省潍坊市', value: 2 },
                { name: '江西省新余市', value: 2 },
                { name: '海南省儋州市', value: 2 },
                { name: '广东省阳江市', value: 2 },
                { name: '江苏省苏州市', value: 2 },
                { name: '青海省西宁市', value: 2 },
                { name: '新疆维吾尔自治区巴音郭楞蒙古自治州', value: 2 },
                { name: '江苏省南京市', value: 2 },
                { name: '江苏省泰州市', value: 2 },
                { name: '海南省', value: 3 },
                { name: '安徽省亳州市', value: 2 },
                { name: '内蒙古自治区乌海市', value: 2 },
                { name: '江苏省扬州市', value: 2 },
                { name: '甘肃省定西市', value: 2 },
                { name: '浙江省舟山市', value: 2 },
                { name: '宁夏回族自治区石嘴山市', value: 1 },
                { name: '北京市平谷区', value: 1 },
                { name: '北京市海淀区', value: 1 },
                { name: '北京市朝阳区', value: 1 },
                { name: '天津市宝坻区', value: 1 },
                { name: '北京市密云区', value: 1 },
                { name: '北京市丰台区', value: 1 },
                { name: '北京市大兴区', value: 1 },
                { name: '安徽省六安市', value: 1 },
                { name: '甘肃省白银市', value: 1 },
                { name: '福建省漳州市', value: 1 },
                { name: '香港特别行政区', value: 1 },
                { name: '广东省珠海市', value: 1 },
                { name: '福建省泉州市', value: 1 },
                { name: '甘肃省甘南藏族自治州', value: 1 },
                { name: '甘肃省武威市', value: 1 },
                { name: '江苏省常州市', value: 1 },
                { name: '青海省果洛藏族自治州', value: 1 },
                // { name: '新疆维吾尔自治区乌鲁木齐市', value: 1 },
                { name: '陕西省延安市', value: 1 },
                { name: '湖北省十堰市', value: 1 },
                { name: '河南省鹤壁市', value: 1 },
                { name: '湖南省张家界市', value: 1 },
                { name: '吉林省延边朝鲜族自治州', value: 1 },
                { name: '吉林省松原市', value: 1 },
                { name: '内蒙古自治区阿拉善盟', value: 1 },
                { name: '甘肃省平凉市', value: 1 },
                { name: '甘肃省庆阳市', value: 1 },
                { name: '浙江省嘉兴市', value: 1 },
                { name: '山西省阳泉市', value: 1 },
                { name: '天津市宁河区', value: 1 },
                { name: '天津市蓟州区', value: 1 },
                { name: '内蒙古自治区锡林郭勒盟', value: 1 },
                { name: '安徽省滁州市', value: 1 },
                { name: '北京市延庆区', value: 1 },
                { name: '山东省东营市', value: 1 },
                { name: '陕西省汉中市', value: 1 },
            ]

        const geoCoordMap = {
            "四川省成都市": [104.065735, 30.659462],
            "广东省广州市": [113.264385, 23.129112],
            "北京市": [116.407396, 39.904211],
            "新疆维吾尔自治区": [87.617733, 43.792818],
            "重庆市": [106.504962, 29.533155],
            "甘肃省": [103.823557, 36.058039],
            "广西壮族自治区柳州市": [109.411703, 24.314632],
            "广西壮族自治区南宁市": [108.366152, 22.82402],
            "广西壮族自治区": [108.320004, 22.82402],
            "贵州省黔东南苗族侗族自治州": [108.845272, 26.583352],
            "宁夏回族自治区": [106.278179, 38.46637],
            "四川省南充市": [106.082974, 30.795811],
            "四川省乐山市": [103.755393, 29.582024],
            "贵州省贵阳市": [106.713478, 26.578343],
            "云南省昭通市": [103.717216, 27.336999],
            "贵州省毕节市": [105.284852, 27.301693],
            "贵州省铜仁市": [109.191555, 27.718745],
            "山东省济宁市": [116.587245, 35.415005],
            "四川省自贡市": [104.773447, 29.352765],
            "广西壮族自治区玉林市": [110.174346, 22.63136],
            "青海省": [101.778916, 36.623178],
            "四川省宜宾市": [104.630825, 28.760189],
            "江西省南昌市": [115.892151, 28.676493],
            "陕西省": [108.948029, 34.263161],
            "江苏省徐州市": [117.283042, 34.261792],
            "湖南省长沙市": [112.982279, 28.19409],
            "河北省石家庄市": [114.502461, 38.045474],
            "广西壮族自治区梧州市": [111.246266, 23.474803],
            "江苏省宿迁市": [118.295544, 33.945154],
            "河南省郑州市": [113.665412, 34.757975],
            "西藏自治区拉萨市": [91.132212, 29.660361],
            "甘肃省兰州市": [103.834303, 36.061089],
            "上海市": [121.472644, 31.231706],
            "重庆市巫山县": [109.878928, 31.074843],
            "西藏自治区日喀则市": [88.885148, 29.263352],
            "内蒙古自治区通辽市": [122.263119, 43.617429],
            "河南省洛阳市": [112.434468, 34.663041],
            "四川省达州市": [107.50161, 31.209616],
            "江西省赣州市": [114.93503, 25.85093],
            "四川省巴中市": [106.747288, 31.867759],
            "四川省德阳市": [104.397794, 31.127991],
            "湖北省宜昌市": [111.290843, 30.702636],
            "四川省绵阳市": [104.741722, 31.46402],
            "广西壮族自治区钦州市": [108.624175, 21.979933],
            "湖南省": [112.982279, 28.19409],
            "云南省保山市": [99.167133, 25.111802],
            "河北省": [114.502461, 38.045474],
            "福建省龙岩市": [117.0404, 25.09129],
            "辽宁省沈阳市": [123.429096, 41.796767],
            "广西壮族自治区贵港市": [109.602246, 23.0936],
            "安徽省阜阳市": [115.820208, 32.896969],
            "甘肃省临夏回族自治州": [103.212006, 35.59945],
            "贵州省遵义市": [106.937265, 27.706626],
            "河南省": [113.665412, 34.757975],
            "天津市": [117.190182, 39.125596],
            "重庆市荣昌区": [105.594946, 29.403627],
            "贵州省黔南布依族苗族自治州": [107.517156, 26.258219],
            "安徽省": [117.283042, 31.861191],
            "河北省邯郸市": [114.490686, 36.612273],
            "河南省南阳市": [112.540921, 32.999082],
            "浙江省": [120.153576, 30.287459],
            "四川省": [104.065735, 30.659462],
            "湖北省黄石市": [115.090164, 30.216127],
            "四川省内江市": [105.066138, 29.586255],
            "吉林省长春市": [125.3245, 43.886841],
            "江苏省连云港市": [119.178832, 34.600018],
            "黑龙江省哈尔滨市": [126.642464, 45.803775],
            "四川省凉山彝族自治州": [102.258746, 27.886763],
            "河北省邢台市": [114.504844, 37.0683],
            "四川省广安市": [106.633418, 30.456398],
            "湖北省荆州市": [112.23813, 30.335165],
            "广西壮族自治区桂林市": [110.299121, 25.274215],
            "内蒙古自治区鄂尔多斯市": [109.781327, 39.608266],
            "江西省": [115.892151, 28.676493],
            "湖北省荆门市": [112.204251, 31.03542],
            "吉林省四平市": [124.370785, 43.170344],
            "河北省保定市": [115.49481, 38.867657],
            "四川省遂宁市": [105.571331, 30.513311],
            "云南省": [102.71225, 25.040609],
            "浙江省杭州市": [120.153576, 30.287459],
            "四川省广元市": [105.829757, 32.433668],
            "安徽省宿州市": [116.984084, 33.633891],
            "四川省眉山市": [103.831788, 30.048318],
            "湖北省武汉市": [114.298572, 30.584355],
            "山东省": [117.12, 36.65],
            "贵州省": [106.713478, 26.578343],
            "福建省福州市": [119.306239, 26.075302],
            "广西壮族自治区贺州市": [111.552004, 24.414141],
            "重庆市渝北区": [106.512449, 29.601451],
            "四川省雅安市": [103.001033, 29.987722],
            "广西壮族自治区来宾市": [109.229772, 23.733819],
            "吉林省": [125.3245, 43.886841],
            "吉林省辽源市": [125.145347, 42.902692],
            "山西省太原市": [112.549248, 37.857014],
            "重庆市长寿区": [107.074854, 30.275027],
            "四川省资阳市": [104.641918, 30.132191],
            "重庆市奉节县": [109.466541, 31.018266],
            "广西壮族自治区百色市": [106.616982, 23.897675],
            "西藏自治区山南市": [91.766529, 29.663754],
            "黑龙江省绥化市": [126.989095, 46.637793],
            "安徽省淮北市": [116.795347, 33.971707],
            "黑龙江省齐齐哈尔市": [123.953049, 47.342081],
            "云南省曲靖市": [103.798084, 25.501557],
            "黑龙江省": [126.642464, 45.803775],
            "江西省宜春市": [114.391136, 27.8043],
            "广西壮族自治区河池市": [108.085649, 24.692567],
            "四川省泸州市": [105.441833, 28.889138],
            "山西省": [112.549248, 37.857014],
            "重庆市巴南区": [106.519423, 29.403627],
            "西藏自治区昌都市": [97.178255, 31.136538],
            "湖北省恩施土家族苗族自治州": [109.48699, 30.283114],
            "江西省抚州市": [116.358601, 27.983073],
            "内蒙古自治区呼和浩特市": [111.652084, 40.818311],
            "湖北省": [114.298572, 30.584355],
            "内蒙古自治区乌兰察布市": [113.114543, 41.034105],
            "河南省商丘市": [115.65459, 34.437054],
            "重庆市开州区": [108.413448, 31.161434],
            "重庆市垫江县": [107.348692, 30.330012],
            "重庆市合川区": [106.265542, 30.164944],
            "四川省甘孜藏族自治州": [101.963816, 30.050663],
            "浙江省宁波市": [121.549792, 29.868388],
            "广西壮族自治区崇左市": [107.357567, 22.404108],
            "云南省楚雄彝族自治州": [101.527294, 25.041988],
            "内蒙古自治区": [111.652084, 40.818311],
            "内蒙古自治区包头市": [109.840405, 40.658168],
            "河南省信阳市": [114.075637, 32.123274],
            "西藏自治区那曲市": [92.060214, 31.476004],
            "江苏省": [118.767413, 32.041544],
            "广西壮族自治区北海市": [109.119254, 21.473343],
            "云南省红河哈尼族彝族自治州": [103.384065, 23.366775],
            "山西省吕梁市": [111.134335, 37.524366],
            "重庆市沙坪坝区": [106.4542, 29.541224],
            "辽宁省": [123.429096, 41.796767],
            "重庆市潼南区": [105.841818, 30.189757],
            "湖南省衡阳市": [112.612251, 26.900358],
            "湖北省黄冈市": [114.879362, 30.846113],
            "重庆市万州区": [108.380281, 30.807667],
            "新疆维吾尔自治区喀什地区": [75.989138, 39.467664],
            "河南省周口市": [114.649653, 33.620349],
            "广东省佛山市": [113.122453, 23.028762],
            "湖南省岳阳市": [113.132855, 29.37029],
            "江西省吉安市": [114.986373, 27.111699],
            "重庆市丰都县": [107.732983, 29.863452],
            "湖南省怀化市": [109.97824, 27.550082],
            "云南省普洱市": [100.972344, 22.777321],
            "湖南省邵阳市": [111.46923, 27.237842],
            "湖北省襄阳市": [112.144146, 32.042426],
            "重庆市云阳县": [108.697729, 30.930529],
            "重庆市永川区": [105.894714, 29.348748],
            "重庆市江津区": [106.259132, 29.290069],
            "重庆市忠县": [108.049957, 30.299937],
            "江西省上饶市": [117.943803, 28.453878],
            "湖南省湘潭市": [112.944052, 27.82973],
            "福建省": [119.306239, 26.075302],
            "浙江省温州市": [120.672111, 27.999383],
            "重庆市石柱土家族自治县": [108.113117, 29.998428],
            "广东省肇庆市": [112.472529, 23.051546],
            "云南省大理白族自治州": [100.241369, 25.5969],
            "湖南省娄底市": [111.994684, 27.728136],
            "河北省衡水市": [115.665993, 37.735097],
            "河南省许昌市": [113.826063, 34.022956],
            "福建省宁德市": [119.527082, 26.65924],
            "浙江省衢州市": [118.872633, 28.941694],
            "重庆市南岸区": [106.563277, 29.523992],
            "广东省汕尾市": [115.364238, 22.786211],
            "云南省昆明市": [102.712251, 25.040609],
            "江西省九江市": [115.892167, 29.37736],
            "福建省莆田市": [119.007558, 25.431011],
            "河南省平顶山市": [113.307718, 33.735241],
            "山西省晋中市": [112.736465, 37.696495],
            "河南省安阳市": [114.352096, 36.097601],
            "重庆市酉阳土家族苗族自治县": [108.772257, 28.839828],
            "广东省": [113.264385, 23.129112],
            "西藏自治区林芝市": [94.362348, 29.653801],
            "山东省烟台市": [121.391382, 37.539297],
            "山西省长治市": [113.113067, 36.201664],
            "重庆市梁平区": [107.800034, 30.672168],
            "重庆市涪陵区": [107.394356, 29.703652],
            "贵州省六盘水市": [104.846244, 26.584643],
            "辽宁省朝阳市": [120.451176, 41.576758],
            "辽宁省鞍山市": [122.994531, 41.110678],
            "河北省沧州市": [116.857461, 38.310582],
            "辽宁省锦州市": [121.135742, 41.119269],
            "重庆市黔江区": [108.769712, 29.533387],
            "重庆市北碚区": [106.437868, 29.795796],
            "重庆市巫溪县": [109.628706, 31.398606],
            "广东省揭阳市": [116.355733, 23.547978],
            "广东省汕头市": [116.681972, 23.354091],
            "贵州省黔西南布依族苗族自治州": [104.897971, 25.088599],
            "河南省开封市": [114.341087, 34.797269],
            "河北省唐山市": [118.49117, 39.635113],
            "湖南省永州市": [111.608019, 26.434516],
            "山西省晋城市": [112.851486, 35.497553],
            "河南省驻马店市": [114.049721, 32.983158],
            "山东省聊城市": [115.980367, 36.456013],
            "重庆市璧山区": [106.231126, 29.593581],
            "重庆市九龙坡区": [106.480825, 29.523234],
            "重庆市大足区": [105.721937, 29.700499],
            "重庆市綦江区": [106.651376, 28.559447],
            "四川省阿坝藏族羌族自治州": [102.221374, 31.899781],
            "河南省焦作市": [113.211836, 35.215057],
            "湖南省郴州市": [113.032067, 25.793589],
            "云南省德宏傣族景颇族自治州": [98.578363, 24.436694],
            "辽宁省葫芦岛市": [120.856394, 40.755572],
            "河北省承德市": [117.939152, 40.976204],
            "陕西省榆林市": [109.741447, 38.290162],
            "湖南省常德市": [111.691347, 29.040225],
            "湖南省株洲市": [113.132855, 27.827546],
            "云南省文山壮族苗族自治州": [104.24401, 23.36951],
            "河南省新乡市": [113.883995, 35.302616],
            "山西省忻州市": [112.734219, 38.461031],
            "湖南省益阳市": [112.355042, 28.570066],
            "广东省茂名市": [110.919229, 21.659752],
            "辽宁省大连市": [121.619621, 38.91459],
            "内蒙古自治区赤峰市": [118.956566, 42.275325],
            "重庆市江北区": [106.532844, 29.575352],
            "西藏自治区": [91.132212, 29.660361],
            "广东省深圳市": [114.057868, 22.543099],
            "广东省云浮市": [112.044439, 22.937976],
            "广东省江门市": [113.094942, 22.590431],
            "贵州省安顺市": [105.92827, 26.228824],
            "广西壮族自治区防城港市": [108.345478, 21.68787],
            "山西省运城市": [111.003957, 35.022778],
            "山东省菏泽市": [115.469381, 35.246531],
            "辽宁省营口市": [122.233391, 40.667432],
            "山西省大同市": [113.295259, 40.09031],
            "重庆市南川区": [107.098153, 29.156646],
            "河南省三门峡市": [111.194099, 34.777338],
            "广东省清远市": [113.056045, 23.664728],
            "云南省玉溪市": [102.543459, 24.350461],
            "湖北省孝感市": [113.926309, 30.926597],
            "山西省临汾市": [111.538788, 36.08415],
            "陕西省西安市": [108.948029, 34.263161],
            "内蒙古自治区呼伦贝尔市": [119.760822, 49.215333],
            "河北省张家口市": [114.884091, 40.811901],
            "重庆市秀山土家族苗族自治县": [108.998619, 28.449041],
            "重庆市铜梁区": [106.054948, 29.839944],
            "浙江省台州市": [121.420757, 28.656386],
            "江西省萍乡市": [113.852186, 27.639544],
            "甘肃省张掖市": [100.449913, 38.932897],
            "山西省朔州市": [112.432091, 39.331261],
            "辽宁省丹东市": [124.383044, 40.03121],
            "重庆市渝中区": [106.56288, 29.556742],
            "广东省河源市": [114.697802, 23.746266],
            "西藏自治区阿里地区": [80.105498, 32.503187],
            "陕西省渭南市": [109.502882, 34.499899],
            "湖北省随州市": [113.379056, 31.717497],
            "辽宁省铁岭市": [123.844279, 42.290585],
            "辽宁省抚顺市": [123.921109, 41.877304],
            "浙江省金华市": [119.647221, 29.089524],
            "吉林省吉林市": [126.55302, 43.843575],
            "山东省枣庄市": [117.323252, 34.811523],
            "辽宁省本溪市": [123.770519, 41.325838],
            "江西省鹰潭市": [117.033838, 28.238638],
            "广东省潮州市": [116.632301, 23.661701],
            "广东省惠州市": [114.41268, 23.079404],
            "广东省东莞市": [113.763434, 23.043024],
            "云南省临沧市": [100.08697, 23.886567],
            "河南省漯河市": [114.026405, 33.575855],
            "黑龙江省牡丹江市": [129.618602, 44.582962],
            "重庆市城口县": [108.664936, 31.946295],
            "黑龙江省佳木斯市": [130.361794, 46.809606],
            "河南省濮阳市": [115.041299, 35.768234],
            "黑龙江省鸡西市": [130.975966, 45.300046],
            "安徽省安庆市": [117.043551, 30.524816],
            "广东省梅州市": [116.117587, 24.288578],
            "广东省湛江市": [110.364976, 21.270145],
            "陕西省安康市": [109.029273, 32.6903],
            "四川省攀枝花市": [101.718637, 26.582347],
            "湖北省鄂州市": [114.890012, 30.396536],
            "吉林省通化市": [125.936501, 41.728401],
            "吉林省白城市": [122.841114, 44.017824],
            "辽宁省辽阳市": [123.237319, 41.267744],
            "云南省丽江市": [100.233026, 26.872108],
            "重庆市武隆区": [107.756556, 29.32376],
            "辽宁省阜新市": [121.660822, 42.011796],
            "黑龙江省大兴安岭地区": [124.196104, 52.335663],
            "辽宁省盘锦市": [122.070284, 41.119997],
            "河北省秦皇岛市": [119.576726, 39.935385],
            "重庆市彭水苗族土家族自治县": [108.166551, 29.293856],
            "海南省海口市": [110.33119, 20.031971],
            "福建省三明市": [117.635001, 26.265444],
            "广东省中山市": [113.382391, 22.521113],
            "甘肃省天水市": [105.724477, 34.578529],
            "青海省玉树藏族自治州": [97.008522, 33.004049],
            "山东省淄博市": [118.047648, 36.814939],
            "山东省泰安市": [117.129063, 36.194968],
            "黑龙江省双鸭山市": [131.157304, 46.655102],
            "湖北省咸宁市": [114.328963, 29.832886],
            "湖南省湘西土家族苗族自治州": [109.739735, 28.314296],
            "内蒙古自治区兴安盟": [122.070351, 46.076481],
            "内蒙古自治区巴彦淖尔市": [107.387834, 40.757402],
            "浙江省湖州市": [120.102398, 30.867252],
            "北京市通州区": [116.657472, 39.902486],
            "河北省廊坊市": [116.704441, 39.529244],
            "黑龙江省伊春市": [128.907554, 47.726851],
            "山东省德州市": [116.307428, 37.453968],
            "陕西省宝鸡市": [107.237743, 34.363157],
            "山东省济南市": [117.120102, 36.651216],
            "福建省南平市": [118.178459, 26.635627],
            "江西省景德镇市": [117.172897, 29.268807],
            "广东省韶关市": [113.591544, 24.801322],
            "甘肃省陇南市": [104.929379, 33.388498],
            "安徽省池州市": [117.489157, 30.656037],
            "安徽省合肥市": [117.283042, 31.861191],
            "青海省海西蒙古族藏族自治州": [97.370785, 37.374555],
            "黑龙江省大庆市": [125.11272, 46.590734],
            "云南省西双版纳傣族自治州": [100.797947, 22.001724],
            "云南省怒江傈僳族自治州": [98.854304, 25.850949],
            "江苏省无锡市": [120.301663, 31.574729],
            "浙江省丽水市": [119.922795, 28.451993],
            "安徽省蚌埠市": [117.389557, 32.939667],
            "甘肃省酒泉市": [98.510795, 39.744023],
            "北京市房山区": [115.994011, 39.735546],
            "江苏省淮安市": [119.015288, 33.597506],
            "江苏省盐城市": [120.139998, 33.377631],
            "山东省滨州市": [118.016489, 37.382542],
            "山东省青岛市": [120.382636, 36.066395],
            "山东省日照市": [119.461222, 35.428588],
            "江苏省南通市": [120.865138, 32.016212],
            "重庆市大渡口区": [106.48613, 29.481002],
            "山东省潍坊市": [119.161519, 36.7068],
            "江西省新余市": [114.930835, 27.810834],
            "海南省儋州市": [109.576782, 19.517485],
            "广东省阳江市": [111.97704, 21.871517],
            "江苏省苏州市": [120.585297, 31.299379],
            "青海省西宁市": [101.778916, 36.623178],
            "新疆维吾尔自治区巴音郭楞蒙古自治州": [86.150969, 41.764115],
            "江苏省南京市": [118.767413, 32.041544],
            "江苏省泰州市": [119.915176, 32.484882],
            "海南省": [110.33119, 20.031971],
            "安徽省亳州市": [115.782138, 33.844008],
            "内蒙古自治区乌海市": [106.825563, 39.673734],
            "江苏省扬州市": [119.412034, 32.393159],
            "甘肃省定西市": [104.626294, 35.579578],
            "浙江省舟山市": [122.255943, 30.016028],
            "宁夏回族自治区石嘴山市": [106.376657, 39.01333],
            "北京市平谷区": [117.122006, 40.144363],
            "北京市海淀区": [116.298485, 39.929985],
            "北京市朝阳区": [116.486409, 39.92828],
            "天津市宝坻区": [117.310847, 39.716965],
            "北京市密云区": [116.843208, 40.376285],
            "北京市丰台区": [116.286156, 39.863642],
            "北京市大兴区": [116.341719, 39.728908],
            "安徽省六安市": [116.507571, 31.752889],
            "甘肃省白银市": [104.173606, 36.546682],
            "福建省漳州市": [117.647841, 24.51328],
            "香港特别行政区": [114.109497, 22.396428],
            "广东省珠海市": [113.552734, 22.256915],
            "福建省泉州市": [118.675676, 24.874132],
            "甘肃省甘南藏族自治州": [102.91149, 34.986354],
            "甘肃省武威市": [102.634697, 37.929996],
            "江苏省常州市": [119.946973, 31.772752],
            "青海省果洛藏族自治州": [100.242143, 34.473385],
            "新疆维吾尔自治区乌鲁木齐市": [87.617733, 43.792818],
            "陕西省延安市": [109.500511, 36.596537],
            "湖北省十堰市": [110.78511, 32.646908],
            "海南省三沙市": [112.339371, 16.831039],
            "河南省鹤壁市": [114.298572, 35.748236],
            "湖南省张家界市": [110.478985, 29.127401],
            "吉林省延边朝鲜族自治州": [129.513228, 42.896414],
            "吉林省松原市": [124.824019, 45.141548],
            "内蒙古自治区阿拉善盟": [105.729656, 38.844814],
            "甘肃省平凉市": [106.665056, 35.54279],
            "甘肃省庆阳市": [107.638372, 35.734239],
            "浙江省嘉兴市": [120.755505, 30.745],
            "山西省阳泉市": [113.58627, 37.861188],
            "天津市宁河区": [117.82828, 39.328886],
            "天津市蓟州区": [117.407449, 40.045342],
            "内蒙古自治区锡林郭勒盟": [116.090659, 43.944018],
            "安徽省滁州市": [118.316264, 32.303627],
            "北京市延庆区": [115.985173, 40.465325],
            "山东省东营市": [118.496921, 37.461266],
            "陕西省汉中市": [108.04144, 33.077667]
        };

        const convertData = function (data) {
            var res = [];
            for (var i = 0; i < data.length; i++) {
                var geoCoord = geoCoordMap[data[i].name];
                if (geoCoord) {
                    res.push({
                        name: data[i].name,
                        value: geoCoord.concat(data[i].value)
                    });
                }
            }
            return res;
        };

        const initChart = () => {
            if (chartInstance) {
                chartInstance.dispose();
            }
            chartInstance = echarts.init(chartRef.current);
            echarts.registerMap("china", china);

            const option = {
                tooltip: {
                    trigger: 'item',
                    showDelay: 0,
                    transitionDuration: 0.2
                },
                visualMap: {
                    top: 'top',
                    left: 'right',
                    min: 0,
                    max: 689,
                    inRange: {
                        color: [
                            // 'lightBlue',
                            '#508aa1',
                            '#377993',
                            '#13769a',
                            '#055878',
                            '#044660' ,
                            '#033548'
                            
                        ],
                    },
                    text: ['High', 'Low'],
                    calculable: true
                },
                toolbox: {
                    show: true,
                    left: 'left',
                    top: 'top',
                    feature: {
                        saveAsImage: {
                            title: 'Save as image',
                            show: 'true',
                        }
                    }
                },
                geo: {
                    map: "china",
                    silent: true,
                    roam: true,
                },
                series: [
                    {
                        name: 'Sample Collection',
                        type: 'scatter', // 修改为scatter类型
                        coordinateSystem: 'geo', // 指定使用地理坐标系
                        data: convertData(data),
                        symbolSize: function (val) {
                            if (val[2] > 1000) {
                                return 40
                            }else if(val[2] < 10 ){
                                return 12
                            } else if (val[2] < 100) {
                                return val[2] / 10 <= 12 ? 12 : val[2] / 10
                            }  else {
                                return val[2] / 25 <= 12 ? 12 : val[2] / 25
                            }
                        },
                        encode: {
                            value: 2
                        },
                        label: {
                            normal: {
                                formatter: '{b}',
                                position: 'right',
                                show: false,
                                textStyle: {
                                    color: '#fff'
                                }
                            }
                        },
                    }
                ]
            };

            chartInstance.setOption(option);
        };

        initChart();

        // Cleanup function to dispose the chart instance
        return () => {
            if (chartInstance) {
                chartInstance.dispose();
            }
        };
    }
    )

    return <div style={{ textAlign: "center" }}>
        <div ref={chartRef} style={{ height: "1500px", width: '1500px', display: 'flex', justifyContent: 'center' }}></div>
    </div>
}
export default Flights