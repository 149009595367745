import React, { useState } from 'react'
import { Space, Table, Switch, Modal, Button, Form, Row, Col, InputNumber } from 'antd';
import API_ROUTES from '../../../../api'
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react';
import moment from 'moment';
import FormItem from 'antd/es/form/FormItem';

export default function UserList(props) {

    const [currentPage, setCurrentPage] = useState(1)
    const [pageTotalCount, setPageTotalCount] = useState(0)
    const [currentPageSize, setCurrentPageSize] = useState(20)
    const [tableLoading, setTableLoadinng] = useState(false)
    const [pageData, setPageData] = useState([])
    const [statusLoding, setStatusLoding] = useState(false)

    const navigate = useNavigate()


    const fetchData = (pageNum) => {
        setTableLoadinng(true)
        axios.get(API_ROUTES.api_user_list, {
            params: {
                current: pageNum == null ? currentPage : pageNum,
                size: currentPageSize
            },
            headers: {
                "Authorization": localStorage.getItem('userInfo')
            }
        })
            .then(function (response) {
                if (response.data.code === 500) {
                    Modal.error({
                        content: response.data.msg
                    });
                } else {
                    setPageTotalCount(response.data.data.total);
                    setPageData(response.data.data.records);

                    console.log(response.data.data.records)
                }
            })
            .catch(function (error) {
                navigate('/login')
                Modal.error({
                    content: error.response.data.msgDes
                });
            })
            .finally(function () {
                setTableLoadinng(false)
            })
    };


    useEffect(() => {
        fetchData();
    }, []);


    const columns = [
        {
            title: 'User type',
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
            width: '120px',
            render: (_, record) => record.manage === 1 ? '超级管理员' : '普通用户'
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ellipsis: true,
            width: '200px'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
            width: '120px'
        },
        {
            title: 'Organization',
            dataIndex: 'org',
            key: 'org',
            ellipsis: true,
            width: '200px'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            ellipsis: true,
            width: '80px',
            render: (text, record) => {
                return (<Switch
                    loading={statusLoding}
                    disabled={record.manage === 1 ? true : false}
                    checked={text === 1 ? true : false}
                    onClick={() => {
                        setStatusLoding(true)
                        setTableLoadinng(true)
                        axios.post(API_ROUTES.api_manage_audit, null, {
                            params: {
                                id: record.id
                            },
                            headers: {
                                "Authorization": localStorage.getItem('userInfo')
                            }
                        })
                            .then(function (response) {
                                if (response.data.code === 500) {
                                    Modal.error({
                                        content: response.data.msg
                                    });
                                } else {
                                    Modal.success({
                                        content: response.data.msg
                                    });
                                }
                                fetchData()
                            })
                            .catch(function (error) {
                                Modal.error({
                                    content: error.response.data.msgDes
                                });
                            })
                            .finally(function () {
                                setStatusLoding(false)
                                setTableLoadinng(false)
                            })
                    }} />)
            }
        },
        {
            title: 'Current Count',
            dataIndex: 'currentCount',
            key: 'currentCount',
            ellipsis: true,
            width: '120px'
        },
        {
            title: 'Total Count',
            dataIndex: 'totalCount',
            key: 'totalCount',
            ellipsis: true,
            width: '200px',
            render: (text, record) => {
                return (
                    <Form onFinish={(value) => {
                        setTableLoadinng(true);
                        axios.post(API_ROUTES.api_manage_update, {
                            id: record.id,
                            totalCount: value.totalCount
                        }, {
                            headers: {
                                "Authorization": localStorage.getItem('userInfo')
                            }
                        })
                            .then(function (response) {
                                if (response.data.code === 500) {
                                    Modal.error({
                                        content: response.data.msg
                                    });
                                } else {
                                    Modal.success({
                                        content: response.data.msg
                                    });
                                }
                                fetchData()
                            })
                            .catch(function (error) {
                                Modal.error({
                                    content: error.response.data.msgDes
                                });
                            })
                            .finally(function () {
                                setTableLoadinng(false)
                            })
                    }}>
                        <Row>
                            <Col span={12}>
                                <FormItem
                                    name="totalCount"
                                    rules={[{
                                        required: true,
                                        message: 'Please input totalCount!'
                                    }]}
                                    style={{
                                        margin: '0px'
                                    }}
                                >
                                    <InputNumber
                                        defaultValue={text}
                                        size='small'
                                        disabled={record.manage === 1 ? true : false}
                                        style={{
                                            margin: '0px'
                                        }}
                                    />
                                </FormItem>
                            </Col>
                            <Col span={1}></Col>
                            <Col span={11}>
                                <FormItem
                                    style={{
                                        margin: '0px'
                                    }}>
                                    <Button
                                        ghost
                                        type="primary"
                                        size='small'
                                        htmlType="submit"
                                        disabled={record.manage === 1 ? true : false}
                                        style={{
                                            margin: '0px'
                                        }}
                                    >
                                        Submit
                                    </Button>
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                )
            }
        },
        {
            title: 'Last Get Time',
            dataIndex: 'lastGetTime',
            key: 'lastGetTime',
            ellipsis: true,
            width: '160px',
            render: (text) => text === null ? null : moment(text).format('YYYY-MM-DD HH:mm:ss'),
        },
        {
            title: 'Update User',
            dataIndex: 'updateUser',
            key: 'updateUser',
            ellipsis: true,
            width: '160px'
        },
        {
            title: 'Update Time',
            dataIndex: 'updateTime',
            render: (text) => text === null ? null : moment(text).format('YYYY-MM-DD HH:mm:ss'),
            key: 'updateTime',
            ellipsis: true,
            width: '160px'
        },
        {
            title: 'Audit User',
            key: 'audit',
            fixed: 'right',
            width: '90px',
            ellipsis: true,
            render: (text, record) => {
                console.log("==========="+record.uploadStatus)
                return (
                    <Button type='primary' 
                    disabled={record.uploadStatus === 3 ? false : true} 
                    onClick={() => {
                        localStorage.setItem("clickedUser", record.id);
                        props.onPageChange("userdetail");
                    }} >
                        audit
                    </Button>
                )
            }
        },
        {
            title: 'Delete User',
            key: 'delete',
            fixed: 'right',
            width: '110px',
            ellipsis: true,
            render: (text, record) => {
                const idNum = record.id
                return (
                    <Button type='primary' danger disabled={record.manage === 1 ? true : false} onClick={() => {
                        axios.post(API_ROUTES.api_manage_update, {
                            id: idNum,
                            delete: 1
                        }, {
                            headers: {
                                "Authorization": localStorage.getItem('userInfo')
                            }
                        })
                            .then(function (response) {
                                if (response.data.code === 500) {
                                    Modal.error({
                                        content: response.data.msg
                                    });
                                } else {
                                    Modal.success({
                                        content: response.data.msg
                                    });
                                }
                                fetchData()
                            })
                            .catch(function (error) {
                                Modal.error({
                                    content: error.response.data.msgDes
                                });
                            })
                            .finally(function () {
                                setTableLoadinng(false)
                            })
                    }} >
                        delete
                    </Button>
                )
            }

        }
    ];


    const tableData = pageData.map((data) => {
        // let status = '';
        // let statusInfo = '';

        // switch (data.status) {
        //   case 1:
        //     status = '正常';
        //     break;
        //   case 2:
        //     status = '禁用';
        //     break;
        //   default:
        //     status = 'unknown';
        //     statusInfo = 'unknown';
        // }

        return {
            ...data,
            // status,
            // statusInfo,
        };
    });

    const changePageNumber = () => {

    }

    const pageSizeChange = () => {

    }
    return (
        <div style={{ overflow: 'auto' }}>
            <Table
                columns={columns}
                dataSource={tableData}
                bordered={true}
                scroll={{
                    x: '1000',
                    y: '500'
                }}
                size='small'
                loading={tableLoading}
                pagination={{
                    showQuickJumper: true,
                    defaultPageSize: 20,
                    defaultCurrent: 1,
                    current: currentPage,
                    total: pageTotalCount,
                    onChange: changePageNumber,
                    pageSize: currentPageSize,
                    onShowSizeChange: pageSizeChange
                }}
                tableLayout='fixed'
            />
        </div>
    )
}
