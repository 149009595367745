import React, { useState } from 'react'
import { Typography } from 'antd';
import Flights from './EchartsChinaMap';

export default function AboutUs() {

    const { Title, Paragraph } = Typography;
    const [selectedRegions, setSelectedRegions] = useState(
        []
    );

    return (
        <div style={{ margin: '100px 180px' }}>
            <Title style={{ fontWeight: '800' }}>
                About Us
            </Title>
            <Title level={2} style={{ fontWeight: '700' }}>
                GSRD Imputation Server
            </Title>

            <Paragraph style={{ fontSize: '20px', fontWeight: '500' }}>
                The Genome Sequencing of Rare Disease (GSRD)
                -100K<sup style={{ fontSize: '16px' }}>WCH</sup> Haplotype Reference Panel aims to improve
                genomic imputation across diverse Chinese populations.
                Developed using whole genome sequencing (WGS) data from
                22,374 subjects with a total of 321,365,014 variants,
                this panel is part of the broader GSRD-100K<sup style={{ fontSize: '16px' }}>WCH</sup> project.
            </Paragraph>

            <Title level={2} style={{ fontWeight: '700' }}>
                GSRD Project
            </Title>

            <Paragraph style={{ fontSize: '20px', fontWeight: '500' }}>
                The GSRD-100K<sup style={{ fontSize: '16px' }}>WCH</sup> project aims to collect and analyze phenotype information and whole genome sequencing data from 100,000 rare disease patients and 20,000 healthy individuals. This project seeks to uncover the molecular causes and mechanisms of rare diseases, advancing precision diagnosis and innovative treatment techniques. This extensive effort focuses on improving early diagnosis, which is crucial for disease intervention and birth defect prevention.<br />
                Since its initiation in July 2020, the project has conducted comprehensive whole genome sequencing on rare disease cases across various clinical disciplines and undiagnosed hearing loss cases in the CDGC cohort. As of May 2024, it has sequenced 12,791 samples from hearing loss and related syndromes, 11,020 samples from rare disease patients excluding hearing loss, and 11,399 samples from the general population, achieving an average sequencing depth of 47x. The project has amassed over 10 PB of sequencing and initial analysis data and established a biobank capable of storing samples from 400,000 patients and family members.<br />
                With a fully automated nucleic acid extraction, library construction, and high-throughput sequencing pipeline, the project has also developed comprehensive information management systems, including a genetic resource management system, phenotype collection platform, and genomic laboratory management system. Additionally, the GSRD-100K<sup style={{ fontSize: '16px' }}>WCH</sup> project is creating an advanced medical genomic data analysis framework and clinical intelligent decision support system to enhance the analysis and interpretation of genetic data. This infrastructure aims to provide powerful support for genetic research and clinical applications, leading to deeper insights into the genetic basis of complex diseases and improved patient care.
            </Paragraph>

            <Title level={2} style={{ fontWeight: '700' }}>
                GSRD-100K<sup style={{ fontSize: '16px' }}>WCH</sup> Sample Collection across Mainland China
            </Title>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Flights />
            </div>


            {/* <img
                style={{ width: '100%' }}
                alt='Sample Collection across Mainland China'
                src='https://gsrd-web-images.tos-cn-beijing.volces.com/gsrdAboutUs.png' /> */}

            <Title level={2} style={{ fontWeight: '700' }}>
                CONTACT US
            </Title>
            <span style={{ fontSize: '24px', fontWeight: '700' }}>
                For questions or collaboration requests about the GSRD-100K
                <sup style={{ fontSize: '16px' }}>WCH</sup>.
            </span>

            <Paragraph style={{ fontSize: '20px', fontWeight: '500' }}>
                <span style={{ fontWeight: '800', marginRight: '10px' }}>EMAIL:</span>Prof. Yuan, Huijun (yuanhuijun@tmmu.edu.cn)<br />
                <span style={{ fontWeight: '800', marginRight: '10px' }}>EMAIL:</span>Prof. Cheng, Jing (chengjing@wchscu.cn)
            </Paragraph>

            <span style={{ fontSize: '24px', fontWeight: '700' }}>
                For genetic testing and counseling.
            </span>

            <Paragraph style={{ fontSize: '20px', fontWeight: '500' }}>
                <span style={{ fontWeight: '800', marginRight: '10px' }}>EMAIL:</span>Dr. Lu, Yu (luyu@wchscu.cn)
            </Paragraph>

            <span style={{ fontSize: '24px', fontWeight: '700' }}>
                For questions about the website and database.
            </span>

            <Paragraph style={{ fontSize: '20px', fontWeight: '500' }}>
                <span style={{ fontWeight: '800', marginRight: '10px' }}>EMAIL:</span>Dr. Bu, Fengxiao (bufengxiao@wchscu.cn)
            </Paragraph>
        </div>


    )
}
