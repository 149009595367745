import { Select, Row, Col, Modal, Button, Table, InputNumber, Tag, FloatButton, message, Empty, Input, Skeleton } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Search from '..'
import moment from 'moment'
import { ReloadOutlined, CaretUpOutlined } from '@ant-design/icons';
import API_ROUTES from '../../../api'
import axios from 'axios'

export default function SearchInput() {

    const navigate = useNavigate()
    const [messageApi, contextHolder] = message.useMessage();

    const [modelOpen, setModelOpen] = useState(false)
    const [tableLoading, setTableLoading] = useState(false)
    const [tableInfo, setTableInfo] = useState([])

    const [modelChrInfo, setModelChrInfo] = useState(13)
    const [modelPosInfo, setModelPosInfo] = useState(20189473)
    const [modelRefInfo, setModelRefInfo] = useState('C')
    const [modelAltInfo, setModelAltInfo] = useState('T')
    const [currentPage, setCurrentPage] = useState(1)
    const [pageTotalCount, setPageTotalCount] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    // 使用useRef创建一个引用，指向目标元素
    const targetRef = useRef(null);
    const [resResult, setResResult] = useState()
    let intervalId = useRef(null);
    const [detailLoading, setDetailLoading] = useState(false)

    // 点击按钮时触发滚动事件
    const scrollToTarget = () => {
        if (targetRef.current) {
            targetRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };


    useEffect(() => {
        const userInfo = localStorage.getItem('userInfo')
        if (!userInfo) {
            navigate('/login')
        }
        getListInfo(currentPage, pageSize)
        // 设置定时器
        intervalId.current = setInterval(() => getListInfo(currentPage, pageSize), 10 * 1000);
        return () => {
            // 组件卸载时清除定时器
            if (intervalId.current) {
                clearInterval(intervalId.current);
            }
        };
    }, [currentPage, pageSize]); // 依赖项数组中包含currentPage和pageSize，这样当它们变化时会重新设置定时器


    const getListInfo = (page, pageSize) => {
        setTableLoading(true)
        axios.post(API_ROUTES.api_search_searchList, {
            sendTimeOrder: "DESC",
            current: page,
            size: pageSize
        }, {
            headers: {
                Authorization: localStorage.getItem('userInfo')
            }
        })
            .then(function (response) {
                if (response.data.code === 500) {
                    Modal.error({
                        content: response.data.msg
                    });
                } else {
                    setTableInfo(response.data.data)
                    setPageTotalCount(response.data.data.total)
                }
            })
            .catch(function (error) {
            })
            .finally(() => {
                setTableLoading(false)
            });
    }


    const pageSizeChange = (_, currentPage) => {
        setPageSize(currentPage)
    }

    const statusFunc = (value) => {
        switch (value) {
            case 0:
                return (
                    <Tag color="gray" style={{ fontSize: '14px' }}>
                        waiting
                    </Tag>
                )
            case 1:
                return (
                    <Tag color="blue" style={{ fontSize: '14px' }}>
                        processing
                    </Tag>
                )
            case 2:
                return <Tag color="green" style={{ fontSize: '14px' }}>success</Tag>
            default:
                return <Tag color="red" style={{ fontSize: '14px' }}>error</Tag>
        }
    }


    const chrOptions = Array.from({ length: 22 }, (_, i) => ({ value: (i + 1).toString(), label: (i + 1).toString() }))
        .concat({ value: 'X', label: 'X' })
        .concat({ value: 'Y', label: 'Y' });

    return (
        <div style={{ margin: '120px' }}>
            <div style={{ padding: '40px' }}>
                {contextHolder}
                <div style={{ display: "flex", justifyContent: 'space-between' }}>
                    <Button
                        type="primary"
                        onClick={() => setModelOpen(true)}
                        style={{ margin: '10px 0px', fontSize: '18px', display: 'flex', alignItems: 'center' }}>
                        Click to create a new query
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            getListInfo()
                        }
                        }
                        style={{ margin: '10px 0px', fontSize: '18px', display: 'flex', alignItems: 'center' }}>
                        <ReloadOutlined />Reload
                    </Button>
                </div>

                <Table
                    columns={[
                        {
                            title: (
                                <div style={{ fontSize: '18px' }}>
                                    Search Value
                                </div>
                            ),
                            dataIndex: 'searchChr',
                            key: 'searchChr',
                            render: (_, record) =>
                                <span style={{ fontSize: '16px' }}>
                                    {'chr' + record.searchChr + ':' + record.searchPos + '-' + record.searchRef + '-' + record.searchAlt}
                                </span>
                            ,
                            width: 80,
                            ellipsis: true,
                        },
                        {
                            title: (
                                <div style={{ fontSize: '18px' }}>
                                    Status
                                </div>
                            ),
                            dataIndex: 'status',
                            key: 'status',
                            render: (_, record) => (
                                statusFunc(record.status)
                            ),
                            width: 80,
                            ellipsis: true,
                        },
                        {
                            title: (
                                <div style={{ fontSize: '18px' }}>
                                    Search Time
                                </div>
                            ),
                            dataIndex: 'searchTime',
                            key: 'SearchTime',
                            render: (text) =>
                                <div style={{ fontSize: '16px' }}>
                                    {moment(text).format('YYYY-MM-DD HH:mm:ss')}
                                </div>,
                            width: 80,
                            ellipsis: true,
                        },
                        {
                            title: (
                                <div style={{ fontSize: '18px' }}>
                                    Others
                                </div>
                            ),
                            dataIndex: 'others',
                            key: 'others',
                            render: (_, record) => {
                                return (
                                    <div>
                                        {record.status === 2 &&
                                            <Button
                                                size='small'
                                                type='primary'
                                                onClick={async () => {
                                                    setDetailLoading(true)
                                                    const formData = new FormData();
                                                    formData.append('id', record.id);
                                                    await axios.post(API_ROUTES.api_search_parseResult, formData, {
                                                        headers: {
                                                            Authorization: localStorage.getItem('userInfo')
                                                        }
                                                    })
                                                        .then(function (response) {
                                                            if (response.data.code === 500) {
                                                                Modal.error({
                                                                    content: response.data.msg
                                                                });
                                                            } else {
                                                                setResResult(response.data.data)
                                                            }
                                                        })
                                                        .catch(function (error) {
                                                            Modal.error({
                                                                content: 'error'
                                                            });
                                                        })
                                                        .finally(() => {
                                                            setDetailLoading(false)
                                                            scrollToTarget()
                                                        });
                                                }}
                                                style={{ fontSize: '18px', display: 'flex', alignItems: 'center' }}
                                            >
                                                Show detail
                                            </Button>

                                        }
                                    </div>
                                );
                            },
                            width: 80,
                            ellipsis: true,
                        },
                    ]}
                    loading={tableLoading}
                    dataSource={tableInfo.records}
                    scroll={{
                        x: '1500px',
                        y: '500px'
                    }}
                    size='small'
                    tableLayout='fixed'
                    pagination={{
                        showQuickJumper: true,
                        defaultPageSize: 10,
                        defaultCurrent: 1,
                        current: currentPage,
                        total: pageTotalCount,
                        onChange: (pageNum) => {
                            setCurrentPage(pageNum)
                            getListInfo(pageNum, pageSize)
                        },
                        pageSize: pageSize,
                        onShowSizeChange: pageSizeChange
                    }}
                    bordered="true"
                />

                <Modal
                    title={
                        <span style={{ fontSize: '26px', fontWeight: '700' }}>
                            Search for GSRD-100K<sup style={{ fontSize: '14px' }}>WCH</sup>
                        </span>
                    }
                    centered
                    closable
                    open={modelOpen}
                    onOk={() => {
                        setModelOpen(false)
                        if (!modelChrInfo || !modelPosInfo || !modelRefInfo || !modelAltInfo) {
                            messageApi.open({
                                type: 'error',
                                content: 'The query parameter cannot be empty',
                                className: 'custom-class',
                                style: {
                                    marginTop: '40vh',
                                },
                            });
                        } else {
                            axios.post(API_ROUTES.api_search_searchOne, {
                                chr: modelChrInfo,
                                pos: modelPosInfo,
                                ref: modelRefInfo,
                                alt: modelAltInfo
                            }, {
                                headers: {
                                    Authorization: localStorage.getItem('userInfo')
                                }
                            })
                                .then(function (response) {
                                    if (response.data.code === 500) {
                                        Modal.error({
                                            content: response.data.msg
                                        });
                                    } else {
                                        Modal.success({
                                            content: "Sending search information success!"
                                        });
                                        getListInfo();
                                    }
                                })
                                .catch(function (error) {
                                    navigate('/login')
                                    Modal.error({
                                        content: error.response.data.msgDes
                                    });
                                })
                        }
                    }
                    }
                    okText='Search'
                    onCancel={() => setModelOpen(false)}
                    width={800}
                >
                    <div style={{ display: "flex", justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <span style={{ fontSize: "16px", fontWeight: '700', margin: '0px 5px' }}>
                                Chr:
                            </span>
                            <Select
                                defaultValue='13'
                                style={{ width: '120px' }}
                                onChange={(value) => setModelChrInfo(value)}
                                options={chrOptions}
                            />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <span style={{ fontSize: "16px", fontWeight: '700', margin: '0px 5px' }}>
                                Pos:
                            </span>
                            <InputNumber
                                min={0}
                                defaultValue={20189473}
                                style={{ width: '120px' }}
                                onChange={(value) => setModelPosInfo(value)}
                                changeOnWheel
                            />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <span style={{ fontSize: "16px", fontWeight: '700', margin: '0px 5px' }}>
                                Ref:
                            </span>
                            <Input
                                style={{ width: '120px' }}
                                value={modelRefInfo}
                                onChange={e => setModelRefInfo(e.target.value)}
                            />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <span style={{ fontSize: "16px", fontWeight: '700', margin: '0px 5px' }}>
                                Alt:
                            </span>
                            <Input
                                style={{ width: '120px' }}
                                value={modelAltInfo}
                                onChange={e => setModelAltInfo(e.target.value)}
                            />
                        </div>
                    </div>
                    <Row gutter={9} style={{ marginTop: '10px' }}>
                        <Col >
                            <span>example:</span>
                        </Col>
                        <Col
                            onClick={() => {
                                setModelOpen(false)
                                axios.post(API_ROUTES.api_search_searchOne, {
                                    chr: 13,
                                    pos: 20189473,
                                    ref: 'C',
                                    alt: 'T'
                                }, {
                                    headers: {
                                        Authorization: localStorage.getItem('userInfo')
                                    }
                                })
                                    .then(function (response) {
                                        if (response.data.code === 500) {
                                            Modal.error({
                                                content: response.data.msg
                                            });
                                        } else {
                                            Modal.success({
                                                content: "Sending search information success!"
                                            });
                                            getListInfo();
                                        }
                                    })
                                    .catch(function (error) {
                                        navigate('/login')
                                        Modal.error({
                                            content: error.response.data.msgDes
                                        });
                                    })
                            }}>
                            <a>chr13:20189473-C-T</a>
                        </Col>
                    </Row>
                </Modal>
            </div>


            {!resResult ?
                <div ref={targetRef} >
                    <Empty
                        description='No Data'
                        style={{
                            height: '100vh',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column', // 确保是上下排列
                            textAlign: 'center'      // 确保文字居中对齐
                        }} />
                </div> :
                detailLoading ?
                    <Skeleton active paragraph={{
                        rows: 50,
                    }} /> :
                    <Search dataInfo={resResult} />
            }


            <FloatButton.BackTop
                type="primary"
                shape="square"
                icon={
                    <CaretUpOutlined />
                }
                style={{ insetInlineEnd: 94 }}
                description="BACK TO TOP!"
            />

        </div>
    )
}
